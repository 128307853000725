'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        require: 'ngModel',
        link: PwCheckLink
    };

    function PwCheckLink(scope, elem, attrs, ctrl) {
        scope.$watch(attrs.ngModel, function(confirm) {
            if (!confirm) return;
            var pwCheck = getNestedObjectValue(scope, attrs.pwCheck);
            ctrl.$setValidity('pwmatch', pwCheck === confirm);
        });
        scope.$watch(attrs.pwCheck, function(pwd) {
            if (!pwd) return;
            var model = getNestedObjectValue(scope, attrs.ngModel);
            ctrl.$setValidity('pwmatch', model === pwd);
        });
    }

    function getNestedObjectValue(obj, name) {
        if (!name) return undefined;

        var prop,
            props = name.split('.');

        for (var i = 0; i < props.length; i++) {
            prop = props[i];

            obj = obj[prop];
            if (!obj) return undefined;
        }
        return obj;
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
