'use strict';

require('angular');

module.exports = function() {
    return {
        findCtrl: findFormCtrl,
        findScope: findFormScope
    };

    function findFormCtrl(scope, name) {
        //tabs = tabs || 0;
        //var indent = tabs > 0 ? Array(tabs).join(' -') : '';
        //console.log(indent + scope.$id);
        //if (formCtrl.foundScope) return formCtrl.foundScope;
        //var name = formCtrl.$name;
        var ctrl = null;
        var head = scope.$$childHead;
        var next = scope.$$nextSibling;

        if (scope[name] && angular.isFunction(scope[name].$setPristine)) {
            return scope[name];
        }

        if (head) {
            ctrl = findFormCtrl(head, name);
            if (ctrl) return ctrl;
        }

        if (next) {
            ctrl = findFormCtrl(next, name);
            if (ctrl) return ctrl;
        }

        return null;
    }

    function findFormScope(scope, formCtrl) {
        //tabs = tabs || 0;
        //var indent = tabs > 0 ? Array(tabs).join(' -') : '';
        //console.log(indent + scope.$id);
        if (formCtrl.foundScope) return formCtrl.foundScope;
        var name = formCtrl.$name;
        var foundScope = null;
        var head = scope.$$childHead;
        var next = scope.$$nextSibling;

        if (scope[name] && angular.isFunction(scope[name].$setPristine)) {
            return formCtrl.foundScope = scope;
        }

        if (head) {
            foundScope = findFormScope(head, formCtrl);
            if (foundScope) return formCtrl.foundScope = foundScope;
        }

        if (next) {
            foundScope = findFormScope(next, formCtrl);
            if (foundScope) return formCtrl.foundScope = foundScope;
        }

        return null;
    }
}
