'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function($http, $window, fcConfig, fcHtmlUtils) {
    return {
        scope: {
            author: '@',
            title: '@',
            ean: '@',
            format: '@',
            subject: '@',
            pubyear: '@',
            series: '@',
            defaultOrderBy: '@'
        },
        templateUrl: 'tmpl/widgets/catalog/catalogsearchadvanced.html',
        link: CatalogSearchAdvancedLink
    };

    function CatalogSearchAdvancedLink(scope) {
        var vm = scope;
        init();
        loadSearchUri();

        vm.search = function() {
            var parms = [];
            for (var name in vm.filters) {
                if (!vm.filters.hasOwnProperty(name)) continue;
                var value = fcHtmlUtils.sanitizeForUrl(vm.filters[name]);
                parms.push(name + '=' + value);
            }
            var location = vm.searchUri;
            location += location.indexOf('?') < 0
                            ? (parms.length > 0 ? '?' + parms.join('&') : '')
                            : (parms.length > 0 ? '&' + parms.join('&') : '');
            $window.top.location = location;
        };

        function init() {
            vm.filters = {};
            vm.filters.page = 1;
            if (vm.defaultOrderBy) vm.filters.orderBy = vm.defaultOrderBy;

            // Hardcoded variable for advancedsearch page, there are no calls to the server for this page as no content or collection on the page. 
            //  HUP is the only app using this so when if we added another, this should be done a different way, until then however... 
            var metaTags = [
                {
                    "name": "description",
                    "content": "Advanced search options for Harvard University Press books and website."
                },
                {
                    "name": "keywords",
                    "content": "search, advanced search, search by, site search, Harvard University Press, Harvard Press, HUP"
                }
            ];
            appendMeta(metaTags);
        };

        function loadSearchUri() {
            $http.get(fcConfig.apiUrl + '/uri/products').success(function(data) {
                vm.searchUri = data.uri;
            });
        };

        function appendMeta(myTags) {
            if (myTags) {
                var existTags = document.getElementsByTagName('meta');
                if (existTags && existTags.length > 0) {
                    for (var i = 0; i < myTags.length; i++) {
                        var myName = myTags[i].name;
                        var myContent = myTags[i].content;
                        var myProperty = myTags[i].property;
                        if (myName && myName.length > 0 && myContent && myContent.length > 0) {
                            var foundTag = false;
                            var metaTag = null;
                            for (var j = 0; j < existTags.length && !foundTag; j++) {
                                if (myName == existTags[j].name) {
                                    metaTag = existTags[j];
                                    foundTag = true;
                                }
                            }
                            if (!metaTag) {
                                metaTag = document.createElement('meta');
                                metaTag.name = myName;
                            }
                            metaTag.content = myContent;
                            if (myProperty && myProperty.length > 0) {
                                metaTag.property = myProperty;
                            }
                            if (!foundTag) {
                                document.getElementsByTagName('head')[0].appendChild(metaTag);
                            }
                        }
                    }
                }
            }
        };
    }
};


// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals