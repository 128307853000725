'use strict';

require('angular');

module.exports = angular
    .module('widgets.content', [])
    .directive('fcBlog', require('./blog'))
    .directive('fcContent', require('./content'))
    .directive('fcResources', require('./resources'))
    .directive('fcAdsConfig', require('./adsconfig'))
    .directive('fcAd', require('./ad'))
    .directive('fcHupLoebFreeTrial', require('./form/huploebfreetrial'))
    .directive('fcHupDareFreeTrial', require('./form/hupdarefreetrial'))
    .directive('fcHupPermissionForm', require('./form/huppermission'))
    .directive('fcHupPermissionFollowupForm', require('./form/huppermissionfollowup'))
    .directive('fcHupMailList', require('./form/hupmaillist'));