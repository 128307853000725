"use strict";

// ReSharper disable InconsistentNaming

module.exports = function($window, fcUrls, fcHtmlUtils, $analytics) {
    return {
        restrict: 'EA',
        scope: {
            load: '&',
            items: '=',
            initialOrderBy: '@',
            ignoredParams: '@',
            canManipWindow: '@',
            canManipGlobalState: '@',
            canManageNavList: '@'
        },
        controller: GridCtrl
    };

    function GridCtrl($scope, $rootScope, GridStates) {
        var vm = $scope;
        var gridSelf = this;
        this.state = state;
        this.sort = sort;
        this.sortClass = sortClass;
        this.sortContainerClass = sortContainerClass;
        this.anyFilters = anyFilters;
        this.removeFilters = removeFilters;
        this.changeFilter = changeFilter;
        this.prevPage = prevPage;
        this.prevPageClass = prevPageClass;
        this.nextPage = nextPage;
        this.nextPageClass = nextPageClass;
        this.getStartingRecord = getStartingRecord;
        this.getEndingRecord = getEndingRecord;
        this.getTotalRecords = getTotalRecords;
        this.isDisabled = isDisabled;
        this.down = down;
        this.stateValue = stateValue;

        this.initMultiSelect = initMultiSelect;
        this.selectAll = selectAll;
        this.checkAllSelected = checkAllSelected;
        this.calculateSelectedItems = calculateSelectedItems;
        this.anySelected = anySelected;
        this.selectedCount = selectedCount;

        if (vm.canManipWindow) $window.onpopstate = function(event) { popState(event); };

        vm.$on('grid-load-error', function() {
            vm._state = vm._prevState;
            finishLoading();
        });

        vm.$on('grid-load-finish', function(broadcast, params) {
            finishLoading();
            if (params) pushState(params);
            if (gridSelf.checkBoxes) gridSelf.resetMultiSelect();
        });

        vm.$on('grid-clear-filter', function(broadcast, name) { changeFilter(name, null); });
        init();

        function init() {
            if (vm.canManipGlobalState && $rootScope.$state.params) vm._state = $rootScope.$state.params;
            var s = state();
            createDefaultState(s);
            vm._prevState = angular.copy(s);
            finishLoading();
        };

        function initMultiSelect() {
            if (this.checkBoxes) return;
            this.checkBoxes = [];
            for (var i = 0; i < vm.items.values.length; i++) this.checkBoxes[i] = false;
            this.checkBoxes['all'] = false;
            $rootScope.$broadcast('grid-refresh-all-checkbox');
        };

        this.resetMultiSelect = function() {
            gridSelf.checkBoxes = null;
            gridSelf._selectedCount = null;
            gridSelf._anySelected = false;
            $rootScope.$broadcast('grid-clear-checkboxes');
        };

        function selectAll() { for (var i = 0; i < this.checkBoxes.length; i++) this.checkBoxes[i] = this.checkBoxes['all']; };

        function checkAllSelected() {
            var allSelected = true;
            var anySelected = false;
            var selectedCount = 0;
            this.checkBoxes['all'] = true;
            for (var i = 0; i < this.checkBoxes.length; i++) {
                anySelected = this.checkBoxes[i] || anySelected;
                allSelected = this.checkBoxes[i] && allSelected;
                if (this.checkBoxes[i]) selectedCount++;
            }
            this.checkBoxes['all'] = allSelected;
            this._anySelected = anySelected;
            this._selectedCount = selectedCount;
        };

        function anySelected() {
            if (this._anySelected) return true;
            return false;
        };

        function selectedCount() { return this._selectedCount; };

        function calculateSelectedItems() {
            var selectedItems = [];
            for (var i = 0; i < this.checkBoxes.length; i++) {
                if (this.checkBoxes[i] && vm.items.values[i]) {
                    var item = vm.items.values[i];
                    selectedItems.push(item);
                }
            }
            return selectedItems;
        };

        function sort(col) {
            if (isDisabled()) return;
            var s = state();
            vm._prevState = angular.copy(s);
            var direction = 'desc';
            if (!s.orderBy || !s.orderBy.includes(col) || s.orderBy.includes('desc')) direction = 'asc';
            s.orderBy = col + ' ' + direction;
            s.page = 1;
            vm.loading = true;
            vm.load({ state: s });
        };

        function prevPage() {
            if (prevPageDisabled()) return;
            var s = state();
            vm._prevState = angular.copy(s);
            s.page = (vm.items.page || 1) - 1;
            if (s.page < 1) s.page = 1;
            vm.loading = true;

            var orderByValue = '';
            if (typeof (s.orderBy) != 'undefined' && s.orderBy != null) orderByValue = s.orderBy.toString();

            $analytics.eventTrack('prevPage', { category: 'Search', label: 'currState: page: ' + s.page.toString() + ', order: ' + orderByValue });
            vm.load({ state: s });
        };

        function nextPage() {
            if (nextPageDisabled()) return;
            var s = state();
            vm._prevState = angular.copy(s);
            s.page = (vm.items.page || 1) + 1;
            vm.loading = true;

            var orderByValue = '';
            if (typeof (s.orderBy) != 'undefined' && s.orderBy != null) orderByValue = s.orderBy.toString();

            $analytics.eventTrack('nextPage', { category: 'Search', label: 'currState: page: ' + s.page.toString() + ', order: ' + orderByValue });
            vm.load({ state: s });
        };

        function sortClass(col) {
            var s = state();
            if (!s.orderBy || !s.orderBy.includes(col)) return '';
            if (s.orderBy.includes('desc')) return 'glyphicon glyphicon-sort-by-attributes-alt';
            return 'glyphicon glyphicon-sort-by-attributes';
        };

        function down(to, i, requireAdmin) {
            if (requireAdmin && $rootScope.user.role != 'admin') return;
            var list = isa(vm.items) ? vm.items : vm.items.values;
            if (vm.canManipGlobalState || vm.canManageNavList) {
                var navlist = [];
                for (var j = 0; j < list.length; j++) {
                    var item = list[j];
                    var title = '';
                    if ($rootScope.itemTitle) title = $rootScope.itemTitle(item);
                    if (!title && item.name) title = item.name;
                    if (!title && item.id) title = item.id;
                    navlist.push({ id: item.id, title: title });
                }
                var target = vm._globalGridState(to);
                var current = vm._globalGridState();
                current.params = state();
                target.navlist = navlist;
                target.index = i;
            }
            $rootScope.$state.go(to, { id: list[i].id });
        };

        function searchItemClick(item) { $analytics.eventTrack('ItemClick', { category: 'Search', label: 'Item: ' + item.ean + ' viewed from search results.' }); };

        function sortContainerClass() { return isDisabled() ? 'sort-head-disabled' : 'sort-head'; };

        function prevPageClass() { return prevPageDisabled() ? 'disabled' : ''; };

        function nextPageClass() { return nextPageDisabled() ? 'disabled' : ''; };

        function getStartingRecord() { return vm.items ? vm.items.startingRecord : null; };

        function getEndingRecord() { return vm.items ? vm.items.endingRecord : null; };

        function getTotalRecords() { return vm.items ? vm.items.totalRecords : null; };

        function finishLoading() { vm.loading = false; };

        function prevPageDisabled() { return isDisabled() || vm.items.page <= 1; };

        function nextPageDisabled() { return isDisabled() || vm.items.page >= vm.items.totalPages; };

        function isDisabled() { return !vm.items || vm.loading; };

        function anyFilters() {
            var localState = state();
            var defaultState = {};
            createDefaultState(defaultState);

            for (var f in localState) {
                if (!localState.hasOwnProperty(f) || defaultState[f]) continue;
                if (localState[f]) return true;
            }
            return false;
        };

        function removeFilters() {
            var localState = state();
            vm._prevState = angular.copy(localState);

            for (var f in localState) {
                if (!localState.hasOwnProperty(f)) continue;
                localState[f] = undefined;
            }
            createDefaultState(localState);

            vm.loading = true;
            $rootScope.$broadcast('grid-clear-filters');
            vm.load({ state: localState });
        };

        function changeFilter(name, value) {
            normalizeState();
            var s = state();
            if (name) s[fcHtmlUtils.camelize(name)] = value;
            vm.load({ state: s });
        };

        function normalizeState() {
            var localState = state();
            for (var f in localState) {
                if (!localState.hasOwnProperty(f)) continue;
                if (localState[f] === null) localState[f] = undefined;
            }
        };

        function pushState(params) {
            if (vm.isPopped) {
                vm.isPopped = false;
                return;
            }
            if (vm.canManipGlobalState) vm._globalGridState().params = state();
            if (vm.canManipWindow) {
                var location = fcUrls.location();
                var path = location.relative.split('?')[0];
                $analytics.pageTrack(path + params);
                $window.history.pushState(state(), location.title, path + params);
            }
        };

        function popState(event) {
            // can probably just check for state===null, but be carefull for harvard
            if (vm.canManipGlobalState && event.state === null) return;
            if (!vm.canManipWindow) return;
            var s = event.state ? event.state : {};
            vm.isPopped = true;
            vm.loading = true;
            vm.load({ state: { page: s.page, orderBy: s.orderBy } });
        };

        function createDefaultState(currState) {
            if (vm.items && vm.items.page) currState.page = vm.items.page;
            if (vm.initialOrderBy) currState.orderBy = vm.initialOrderBy;
            if (vm.ignoredParams) {
                var ignoredParams = vm.ignoredParams.split('|');
                for (var i = 0; i < ignoredParams.length; i++) {
                    if (!currState.hasOwnProperty(ignoredParams[i])) continue;
                    currState[ignoredParams[i]] = undefined;
                }
            }
        };

        function state() {
            if (!vm._state) vm._state = {};
            return vm._state;
        };

        function stateValue(name) {
            name = fcHtmlUtils.camelize(name);
            if (vm._state && vm._state[name]) return vm._state[name];
            return '';
        }

        vm._globalGridState = function(name) {
            name = name || $rootScope.$state.$current.self.name;
            if (!GridStates[name]) GridStates[name] = {};
            return GridStates[name];
        };
    }
};

// ReSharper restore InconsistentNaming