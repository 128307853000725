'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function ($http, $sce, fcUrls, fcConfig) {
    return {
        restrict: 'EA',
        scope: {
            
        },
        templateUrl: 'tmpl/widgets/catalog/catalogproductcrawl.html',
        link: CatalogProductCrawlLink
    };

    function CatalogProductCrawlLink(scope) {
        var vm = scope,
            baseUrl = fcConfig.apiUrl + '/catalogs/works/crawl';

        load();

        function load() {
            var parms = fcUrls.location().params;

            var apiParms = [];
            for (var p in parms) {
                if (!parms.hasOwnProperty(p)) continue;
                if (!parms[p]) continue;
                apiParms.push(p + '=' + parms[p]);
            }
            var url = baseUrl + (apiParms.length > 0 ? '?' + apiParms.join('&') : '');

            $http.get(url)
                .success(function (data) {
                    vm.products = data;
                });
        };
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
