'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

var Cookies = require('cookies-js');

module.exports = function($http, $window, fcConfig, fcUrls) {
    return {
        restrict: 'EA',
        scope: {
            args: '@fcMe',
            library: '@',
            history: '@',
            shipping: '@',
            billing: '@',
            password: '@',
            gravatar: '@',
            loginLabel: '@',
            layout: '@',
            logoutUrl: '@',
            optInMail: '@'
        },
        templateUrl: 'tmpl/widgets/user/me.html',
        controller: fcMeController,
        link: fcMeLink
    };

    function fcMeController($scope, $controller) {
        $controller('fcCtrl', {
            $scope: $scope
        });
    }

    function fcMeLink(scope) {
        var vm = scope;

        vm.logOut = function () {
            window.__SUSPEND_ENGINE_STATUS__ = true;
            $http['delete'](fcConfig.apiUrl + '/auth')
                .success(function (data) {
                    var appId = scope.$root.appId;
                    Cookies.expire(appId + '-token');
                    if (typeof (data.continue) !== 'undefined') {
                        window.top.location.href = data.continue;
                        if (appId == 'cloud') {
                            window.top.location.reload();
                        }
                    } else {
                        window.top.location.reload();
                    }
                }).error(function (data) {
                    window.top.location.href = '/#!/login';
                    window.top.location.reload();
                });
        };

        vm.login = function() {
            fcUrls.go('/uri/login');
        };

        vm.gotoLibrary = function() {
            fcUrls.go('/uri/library');
        };

        vm.gotoHistory = function() {
            fcUrls.go('/uri/orders');
        };

        vm.$on('auth-success', function(e, user) {
            //vm.user = user;
            init();
        });

        function init() {
            var args = (vm.args || '').toOptions();

            var opts = {
                library: Boolean(vm.library || args.library || args.displayLibrary),
                history: Boolean(vm.history || args.history || args.displayHistory),
                shipping: Boolean(vm.shipping || args.shipping || args.displayShipping),
                billing: Boolean(vm.billing || args.billing || args.displayBilling),
                password: Boolean(vm.password || args.password || args.displayPassword),
                gravatar: Boolean(vm.gravatar || args.gravatar),
                loginLabel: vm.loginLabel || args.loginLabel || 'Sign In',
                layout: vm.layout || args.layout,
                logoutUrl: vm.logoutUrl || args.logoutUrl,
                optInMail: Boolean(vm.optInMail || args.optInMail),
                optInLabel: vm.optInLabel || args.optInLabel
            };

            opts.layoutStyle = '';
            if (opts.layout == 'left')
                opts.layoutStyle = 'nav navbar-nav';
            else if (opts.layout == 'right')
                opts.layoutStyle = 'nav navbar-nav navbar-right';

            vm.opts = opts;
        }

        init();
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals