'use strict';

module.exports = function() {
    return {
        restrict: 'A',
        scope: {
            value: '=fcBool'
        },
        template: '<span class="glyphicon" ng-class="{\'glyphicon-check\': value, \'glyphicon-unchecked\': !value}"></span>'
    };
};
