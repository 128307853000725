'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function ($http, fcUrls, fcConfig) {
    return {
        restrict: 'EA',
        scope: {
            lapp: '@'
        },
        templateUrl: 'tmpl/widgets/content/adsconfig.html',
        link: AdsConfigLink
    };

    function AdsConfigLink(scope) {
        var vm = scope;

        vm.load = load;
        vm.create = create;
        vm.addSection = addSection;
        vm.addProduct = addProduct;
        vm.edit = edit;
        vm.editSection = editSection;
        vm.editProduct = editProduct;
        vm.cancel = cancel;
        vm.closeSection = closeSection;
        vm.closeProduct = closeProduct;
        vm.save = save;
        vm.remove = remove;
        vm.removeSection = removeSection;
        vm.removeProduct = removeProduct;

        load();

        function load(state) {
            vm.page = null;
            var parms = fcUrls.location().params;

            var apiParms = [];
            if (state) {
                vm.state = state;
                if (state.page) parms.page = state.page;
                if (state.orderBy) params.orderBy = state.orderBy;
            }
            for (var p in parms) {
                if (!parms.hasOwnProperty(p)) continue;
                if (!parms[p]) continue;
                apiParms.push(p + '=' + parms[p]);
            }

            var url = fcConfig.apiUrl + '/adslist' + (apiParms.length > 0 ? '?' + apiParms.join('&') : '');
            $http.get(url)
                .success(function (data) {
                    vm.items = data;
                    vm.$broadcast('grid-load-finish');
                })
                .error(function () {
                    vm.$broadcast('grid-load-error');
                    cancel();
                });
        };

        function create() {
            vm.item = { active: true };
        };

        function addSection() {
            vm.item.categories.push({ categoryReferenceId: vm.item.section.categoryReferenceId, name: vm.item.section.name, saved: true });
        };

        function addProduct() {
            vm.item.products.push({ productId: vm.item.product.productId, name: vm.item.product.name, saved: true });
        };

        function edit(resource) {
            var url = fcConfig.apiUrl + '/ads/' + resource.id + (vm.lapp ? '?linkedApp=' + vm.lapp : '');
            $http.get(url)
                .success(function (data) {
                    vm.item = data;
                    var availCatUrl = fcConfig.apiUrl + '/ads/' + resource.id + '/available-categories' + (vm.lapp ? '?linkedApp=' + vm.lapp : '');
                    $http.get(availCatUrl)
                        .success(function (data2) {
                            vm.item.availableSections = data2;
                        });
                    var availProductUrl = fcConfig.apiUrl + '/ads/' + resource.id + '/available-products' + (vm.lapp ? '?linkedApp=' + vm.lapp : '');
                    $http.get(availProductUrl)
                        .success(function (data2) {
                            vm.item.availableProducts = data2;
                        });
                    vm.item.editing = true;
                });
        };

        function editSection() {
            if (vm.item) {
                vm.item.editingSection = true;
            }
        };

        function editProduct() {
            if (vm.item) {
                vm.item.editingProduct = true;
            }
        };

        function cancel() {
            vm.item = null;
        };

        function closeSection() {
            if (vm.item) {
                vm.item.editingSection = false;
            }
        };

        function closeProduct() {
            if (vm.item) {
                vm.item.editingProduct = false;
            }
        }

        function save(newItem) {
            var postUrl = fcConfig.apiUrl + '/ads';
            if (vm.item.editing) {
                postUrl += '/' + vm.item.id;
            }

            postUrl += '?name=' + vm.item.name;
            if (vm.item.active) {
                postUrl += '&active=' + vm.item.active;
            }
            if (vm.lapp) {
                postUrl += '&linkedApp=' + vm.lapp;
            }

            $http.post(postUrl)
                .success(function(data) {
                    if (!newItem) {
                        saveSections();
                        saveProducts();
                        cancel();
                        load(vm.state);
                    } else { //newItem
                        edit({ id: data.id });
                    }
                    vm.$broadcast('grid-load-finish');
                })
                .error(function (data) {
                    vm.$broadcast('grid-load-error');
                    cancel();
                    load(vm.state);
                });

            if (!newItem) {
                vm.$broadcast('content-' + vm.item.contentName + '-save');
            }
        };

        function saveSections() {
            var length = vm.item.categories.length;
            for (var i = 0; i < length; i++) {
                var curCategory = vm.item.categories[i];
                var sectionPostUrl = fcConfig.apiUrl + '/ads/' + vm.item.id + '/categories/' + curCategory.categoryReferenceId + (vm.lapp ? '?linkedApp=' + vm.lapp : '');
                if (curCategory.deleted) {
                    $http.delete(sectionPostUrl)
                        .success(function (data) {

                        });
                }
                else if (curCategory.saved) {
                    $http.post(sectionPostUrl)
                        .success(function (data) {

                        });
                }
            }
        };

        function saveProducts() {
            var length = vm.item.products.length;
            for (var i = 0; i < length; i++) {
                var curProduct = vm.item.products[i];
                var productPostUrl = fcConfig.apiUrl + '/ads/' + vm.item.id + '/products/' + curProduct.productId;
                if (curProduct.deleted) {
                    $http.delete(productPostUrl)
                        .success(function (data) {

                        });
                }
                else if (curProduct.saved) {
                    $http.post(productPostUrl)
                        .success(function (data) {

                        });
                }
            }
        };

        function remove() {
            if (vm.item.id && confirm('Are you certain you want to delete this?')) {
                var postUrl = fcConfig.apiUrl + '/ads/' + vm.item.id + (vm.lapp ? '?linkedApp=' + vm.lapp : '');
                $http.delete(postUrl).success(function (data) {
                    cancel();
                    load(vm.state);
                });
            }
        };

        function removeSection(section) {
            var length = vm.item.categories.length;
            var found = false;
            for (var i = 0; i < length && !found; i++) {
                if (vm.item.categories[i].id == section.id) {
                    vm.item.categories[i].saved = false;
                    vm.item.categories[i].deleted = true;
                    found = true;
                }
            }
        };

        function removeProduct(product) {
            var length = vm.item.products.length;
            var found = false;
            for (var i = 0; i < length && !found; i++) {
                if (vm.item.products[i].id == product.id) {
                    vm.item.products[i].saved = false;
                    vm.item.products[i].deleted = true;
                    found = true;
                }
            }
        };
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals