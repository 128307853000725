"use strict";

// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        restrict: 'EA',
        require: '^fcGrid',
        replace: true,
        scope: {
            name: '@',
            options: '='
        },
        template: '\
            <button class="btn btn-link btn-sm" style="padding-bottom: 1px" ng-show="anyFilters()" ng-click="removeFilters()" uib-tooltip="Remove Filters" tooltip-placement="bottom" type="button">\
                <span class="glyphicon glyphicon-erase"></span>\
            </button>',
        link: ClearFilterLink
    };

    function ClearFilterLink(scope, elems, attrs, gridCtrl) {
        var vm = scope;
        vm.removeFilters = function() { gridCtrl.removeFilters(); };
        vm.anyFilters = gridCtrl.anyFilters;
    }
};
// ReSharper restore InconsistentNaming