'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function($http) {
    return {
        scope: {
            options: '@fcBlog'
        },
        templateUrl: 'tmpl/widgets/content/blog.html',
        controller: BlogCtrl,
        link: BlogLink
    };

    function BlogCtrl($scope, $controller, fcConfig, fcUrls) {
        $controller('fcCtrl', {
            $scope: $scope
        });

        $scope.loadBlog = function () {
            var url = fcConfig.apiUrl + '/blogfeed';

            var maxEntries = 0;
            var maxEntryLength = 0;
            if ($scope.options) {
                var opts = $scope.options.toOptions({});
                if (opts) {
                    var parms = fcUrls.location().params;
                    if (opts.entries) {
                        parms.maxEntries = opts.entries;
                    }
                    if (opts.maxlength) {
                        parms.maxLength = opts.maxlength;
                    }
                    if (opts.articleurls) {
                        parms.articleUrls = opts.articleurls;
                    }

                    var apiParms = [];
                    for (var p in parms) {
                        if (!parms.hasOwnProperty(p)) continue;
                        if (!parms[p]) continue;
                        apiParms.push(p + '=' + parms[p]);
                    }
                    url += (apiParms.length > 0 ? '?' + apiParms.join('&') : '');
                }
            }
            $http.get(url)
                .success(function(data) {
                    $scope.articles = data.articles;
                })
                .error(function(data) {
                    if (!data) return;
                    var error = data.responseStatus || data;
                    $scope.blogHtml = 'Error: ' + (error.message || status);
                });
        };
    }

    function BlogLink(scope) {
        scope.loadBlog();
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
