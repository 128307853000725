"use strict";

// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        restrict: 'EA',
        require: '^fcGrid',
        replace: true,
        scope: {},
        template: '\
            <form class="form-inline" data-ng-submit="search()">\
                <div class="right-inner-addon">\
                    <i class="glyphicon glyphicon-search text-muted small"></i>\
                    <input type="search" class="form-control input-sm filter" placeholder="Search" ng-model="model" ng-change="markChanged()" ng-blur="search()" />\
                    <input type="submit" ng-show="false" />\
                </div>\
            </form>',
        link: SearchLink
    };

    function SearchLink(scope, elems, attrs, gridCtrl) {
        var vm = scope;
        vm.model = '';
        if (gridCtrl.stateValue('search')) vm.model = gridCtrl.stateValue('search');

        vm.$on('grid-clear-filters', function () { vm.model = ''; });

        vm.markChanged = function () { vm.hasChanged = true; };

        vm.search = function() {
            if (vm.hasChanged) {
                vm.hasChanged = false;
                gridCtrl.changeFilter('search', vm.model);
            }
        };
    }
};
// ReSharper restore InconsistentNaming