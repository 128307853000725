'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function($http, $sce, $window, fcUrls, fcConfig) {
    return {
        restrict: 'EA',
        scope: {
            mode: '@',
            registration: '@',
            actionUrl: '@',
            optInMail: '@',
            optInLabel: '@',
            optInUncheck: '@',
            title: '@'
        },
        template: '<div class="fc fc-widget fc-login"><div data-ng-include="view.url"></div></div>',
        controller: fcLoginController,
        link: fcLoginLink
    };

    function fcLoginController($scope, $controller) {
        $controller('fcCtrl', {
            $scope: $scope
        });
    }

    function fcLoginLink(scope) {
        var vm = scope;

        vm.forgotPassword = forgotPassword;
        vm.registerAccount = registerAccount;
        vm.showRegistration = showRegistration;
        vm.cancelRegistration = cancelRegistration;
        vm.resetEmail = resetEmail;
        vm.continueAsGuest = continueAsGuest;
        vm.authenticate = authenticate;
        vm.switchView = switchView;

        if (fcConfig.appId.toLowerCase() === 'hup') {
            vm.authVersion = 2;
        } else {
            vm.authVersion = 1;
        }

        vm.views = {
            credentials: {
                errorMsg: 'Invalid Email / Password',
                url: 'tmpl/widgets/user/login/credentials.html'
            },
            resetPassword: {
                errorMsg: 'Invalid / Unregistered Email',
                url: 'tmpl/widgets/user/login/forgotPassword.html'
            },
            registration: {
                errorMsg: 'Invalid Email',
                url: 'tmpl/widgets/user/login/registration.html'
            },
            impersonation: {
                errorMsg: 'Invalid Email / Password Impersonation',
                url: 'tmpl/widgets/user/login/impersonation.html'
            },
            delegation: {
                errorMsg: 'Invalid Email / Access Token',
                url: 'tmpl/widgets/user/login/delegation.html'
            },
            checkoutTypeGuest: {
                url: 'tmpl/widgets/user/login/checkoutTypeGuest.html'
            },
            checkoutTypeCreate: {
                url: 'tmpl/widgets/user/login/checkoutTypeCreate.html'
            },
            checkoutTypeExisting: {
                url: 'tmpl/widgets/user/login/checkoutTypeExisting.html'
            }
        };

        function init() {
            vm.mode = vm.mode || 'credentials';
            vm.registration = Boolean(vm.registration);
            vm.optInMail = Boolean(vm.optInMail);
            vm.optInLabel = vm.optInLabel || 'I would like to receive new book announcements and exclusive discounts. Sign me up.';
            vm.optInUncheck = Boolean(vm.optInUncheck);
            vm.title = vm.title || 'Sign In';
            vm.checkoutType = vm.checkoutType || 'checkoutTypeExisting';
            vm.user = {};
            vm.user.checkoutType = vm.user.checkoutType || 'checkoutTypeExisting';

            vm.view = vm.views[vm.mode];
            var query = '';
            if (fcConfig.apiKey) {
                query = '?api_key=' + fcConfig.apiKey + '&app=' + fcConfig.appId;
            } else {
                query = '?app=' + fcConfig.appId;
            }

            var secureApiUrl = fcConfig.apiUrl.replace(/^http:\/\//i, "https://");
            vm.trustedActionUrl = vm.actionUrl || $sce.trustAsResourceUrl(secureApiUrl + '/auth' + query);

            var l = fcUrls.location();
            vm.redirect = l.params.redirect;
            var error = l.params.error;
            if ( error && error.toLowerCase() === 'unauthorized') {
                vm.errorMsg = vm.view ? vm.view.errorMsg : 'An error has occurred.';
            } else if (error) {
                vm.errorMsg = 'An error has occurred.';
            }
            vm.buttonDisabled = false;

            if (!vm.redirect) {
                vm.redirect = l.params.ReturnUrl;
            }
           // vm.opts = opts;
        };

        function wipeMessage() {
            vm.errorMsg = null;
            vm.msg = null;
            vm.msgType = null;
        }

        function switchView(value) {
            vm.user.userName = "";
            vm.checkoutType = value;
            vm.view = vm.views[value];
        }

        function forgotPassword() {
            wipeMessage();
            vm.view = vm.views['resetPassword'];
        };

        function cancelRegistration() {
            wipeMessage();
            vm.view = vm.views[vm.mode];
        };

        function registerAccount() {
            vm.registerText = 'Registering...';

            if (!vm.reg.continue ) {
                vm.reg.continue = vm.redirect;
            }

            this.reg.sendAuthToken = true; // tells the API to set an authentication cookie in the response

            if (vm.authVersion === 1) {
                $http.post(fcConfig.apiUrl + '/users', this.reg)
                    .success(function(data) {
                        if (data.continue) {
                            $window.top.location = data.continue;
                        } else {
                            $window.top.location = vm.reg.continue;
                        } 
                    })
                    .error(function() {
                        vm.registerText = 'Register';
                    });
            } else if (vm.authVersion === 2) {
                var clientBaseUrl = getClientBaseUrl();
                $http.post(clientBaseUrl + '/register?responseFormat=json', {
                    firstName: vm.reg.firstName,
                    lastName: vm.reg.lastName,
                    email: vm.reg.email,
                    password: vm.reg.password,
                    confirmPassword: vm.reg.confirmPassword
                }).success(function(data) {
                    window.top.location.href = clientBaseUrl;
                }).error(function() {
                    vm.registerText = 'Register';
                });
            }
        };

        function continueAsGuest() {
            vm.user.continue = vm.redirect;
            if (vm.authVersion === 1) {
                $http.post(fcConfig.apiUrl + '/users/guest', { email: vm.user.userName })
                    .success(function (data) {
                        if (data.continue) {
                            $window.top.location = data.continue;
                        }
                    }).error(function () {
                        vm.errorMsg = vm.view.errorMsg;
                    });
            } else if (vm.authVersion === 2) {
                var clientBaseUrl = getClientBaseUrl();
                $http.post(clientBaseUrl + '/login?responseFormat=json', { email: vm.user.userName, userIsGuest: true })
                    .success(function (data) {
                        if (typeof(data.continue) !== 'undefined') {
                            window.top.location.href = data.continue;
                        }
                    }).error(function () {
                        vm.errorMsg = vm.view.errorMsg;
                    });
            }
        };

        function getClientBaseUrl() {
            var clientBaseUrl = '',
                currentUrl = document.location.href;
            if (currentUrl.indexOf('/login') !== -1) {
                clientBaseUrl = currentUrl.substr(0, currentUrl.indexOf('/login'));
            } else if (currentUrl.indexOf('/cart') !== -1) {
                clientBaseUrl = currentUrl.substr(0, currentUrl.indexOf('/cart'));
            }
            return clientBaseUrl;
        }

        function showRegistration( location) {
            vm.reg = {};
            vm.registerText = 'Register';
            var clientBaseUrl = getClientBaseUrl();

            if (location == 'checkout') {
                var l = fcUrls.location();
                if (vm.authVersion === 1) {
                    vm.reg.continue = l.path + '?v=b';
                }
                else if (vm.authVersion === 2) {
                    vm.reg.continue = clientBaseUrl;
                }
            }

            if (vm.optInMail) {
                if (vm.optInUncheck) {
                    vm.reg.optInMail = false;
                } else {
                    vm.reg.optInMail = true;
                }
            }
            vm.view = vm.views['registration'];

        };

        function authenticate() {
            var ActionUrl = fcConfig.apiUrl + '/auth';

            $http({
                url: ActionUrl,
                method: 'Post',
                data: vm.user
            }).success(function(data) {
                if (data.continue) {
                    fcUrls.go('/uri/cart/checkout'); 
                }
            }).error(function (data) {
                vm.errorMsg = vm.view.errorMsg;
            });
        }

        function resetEmail() {
            wipeMessage();
            vm.buttonText = vm.view.progressText;
            vm.buttonDisabled = true;
            $http.post(fcConfig.apiUrl + '/users/password/reset', {
                    email: this.auth.userName
                })
                .success(function () {

                    vm.msgType = 'success';
                    vm.msg = 'A new password has been sent to your email.\nPlease check your inbox.';
                    vm.view = vm.views['credentials'];
                    vm.buttonText = vm.view.buttonText;
                    vm.buttonDisabled = false;
                })
                .error(function(data, status) {
                    vm.msgType = 'danger';
                    if (status == 403)
                        vm.msg = vm.view.errorMsg;
                    else {
                        vm.msg = 'Unsuccessful!';
                        vm.errorMsg = vm.view.errorMsg;
                    }
                    vm.buttonText = vm.view.buttonText;
                    vm.buttonDisabled = false;
                });
        };
        init();
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals