'use strict';

var jquery = require("jquery"),
    lodash = require("lodash");
    require('angular');
    require('ui-bootstrap');
    require('froala-editor');
    require('angular-animate');
    require('ng-file-upload');
    require('angular-progress-arc');
    require('./framework/angulartics');
    require('./framework/angulartics-google-analytics');

    require('froala-upload');
    require('froala-fullscreen');
    require('froala-colors');
    var Cookies = require('cookies-js');

    require('./widgets.tmpl.js');


    jquery.noConflict(true);
    lodash.noConflict();

angular.module('widgets', [
        'ui.bootstrap',
        'widgets.tmpl',
        'angulartics',
        'angulartics.google.analytics',
        require('./framework').name,
        require('./cart').name,
        require('./catalog').name,
        require('./content').name,
        require('./upload').name,
        require('./engine').name,
        require('./library').name,
        require('./user').name,
        require('./tmm').name
    ])
    .config(WidgetsConfig)
    .value('_', lodash)
    .run(WidgetsRun);

// ReSharper disable InconsistentNaming

function WidgetsConfig($locationProvider,
                       $httpProvider,
                       $sceDelegateProvider,
                       $tooltipProvider,
                       $provide,
                       $logProvider,
                       fcConfigProvider
                       ) {
    console.log('Widgets Initialization...');
    $logProvider.debugEnabled(true);
    $locationProvider.html5Mode(false);
    $httpProvider.defaults.withCredentials = true;
    $tooltipProvider.options({
        popoverMode: 'single'
    });

    //$httpProvider.defaults.headers.common['X-Ajax'] = 'true';
    $sceDelegateProvider.resourceUrlWhitelist(['self', window.ROOTURL + '/**']);

    var apiKey = fcConfigProvider.apiKey;
    var appId = fcConfigProvider.appId;
    var direct = fcConfigProvider.direct;

    if (appId) appId = appId.toLowerCase();

    $httpProvider.interceptors.push(Interceptors);

    function Interceptors($q, $rootScope, $log) {
        return {
            'request': RequestInterceptor,
            'response': ResponseInterceptor,
            'responseError': ResponseErrorInterceptor
        };

        function RequestInterceptor(config) {
            //$log.debug('RequestInterceptor: ' + config.url);
            if (!config.url.startsWith('template/') &&
                !config.url.startsWith('tmpl/')) {
                if (apiKey) {
                    config.headers['X-Fcs-ApiKey'] = apiKey;
                }

                if (direct) {
                    config.headers['X-Fcs-Direct'] = 'true';
                }

                if (appId) {
                    config.headers['X-Fcs-App'] = appId;
                }
                // ReSharper disable UseOfImplicitGlobalInFunctionScope
                var token = Cookies.get(appId + '-token');
                var session = Cookies.get(appId + '-session');
                var ticket = Cookies.get('fcs-ticket');
                // ReSharper restore UseOfImplicitGlobalInFunctionScope
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }
                if (session) {
                    config.headers['X-Fcs-Session'] = session;
                }
                if (ticket) {
                    config.headers['X-Fcs-Ticket'] = ticket;
                }
            }
            if (config.method === 'POST' ||
                config.method === 'PUT' ||
                config.method === 'DELETE') {
                $rootScope.$broadcast('loading-started');
                $rootScope.$broadcast('onErrorReset');
            }

            return config || $q.when(config);
        }

        function ResponseInterceptor(response) {
            var headers = response.headers;
            var loc = headers('location') || headers('x-location');
            if (loc) {
                //$log.debug('ResponseInterceptor: ' + response.config.url + ' redirect to ' + loc);
                window.location = loc;
            }

            if (response.config.method === 'POST' ||
                response.config.method === 'PUT' ||
                response.config.method === 'DELETE') {
                $rootScope.$broadcast('loading-stopped');
            }

            //if (!response.config.url.startsWith('template/') &&
            //    !response.config.url.startsWith('tmpl/')) {

            //    var session = headers('X-Fcs-Session');
            //    var ticket = headers('X-Fcs-Ticket');
            //    var sessionCookie = readCookie('fcs-session');
            //    var ticketCookie = readCookie('fcs-ticket');

            //    if (session && (!sessionCookie || sessionCookie !== session)) {
            //        eraseCookie('fcs-session');
            //        createCookie('fcs-session', session, 14);
            //    }
            //    if (ticket && (!ticketCookie || ticketCookie !== ticket)) {
            //        eraseCookie('fcs-ticket');
            //        createCookie('fcs-ticket', ticket);
            //    }
            //}

            return response || $q.when(response);
        }

        function ResponseErrorInterceptor(rejection) {
            var loc = rejection.headers()['location'] || rejection.headers()['x-location'];
            if (loc) {
                $log.debug('ResponseErrorInterceptor: ' + rejection.config.url + ' redirect to ' + loc);
                window.location = loc;
            }
            if (rejection.config.method == 'POST' ||
                rejection.config.method == 'PUT' ||
                rejection.config.method == 'DELETE') {
                $rootScope.$broadcast('loading-stopped');
            }
            $rootScope.$broadcast('onError', rejection.data);
            return $q.reject(rejection);
        }
    };
};

function WidgetsRun($rootScope, $templateCache, $compile, $window, fcConfig, fcAuth, $analytics) {
    $rootScope.apiKey = fcConfig.apiKey;
    $rootScope.appId = fcConfig.appId;
    $rootScope.direct = fcConfig.direct;

    //$templateCache.put("template/popover/popover-html.html",
    //    "<div class=\"popover {{placement}}\" ng-class=\"{ in: isOpen(), fade: animation() }\">\n" +
    //    "  <div class=\"arrow\"></div>\n" +
    //    "\n" +
    //    "  <div class=\"popover-inner\">\n" +
    //    "      <h4 class=\"popover-title\" ng-bind=\"title\" ng-show=\"title\"></h4>\n" +
    //    "      <div class=\"popover-content\" ng-bind-html=\"content | to_trusted\">    </div>\n" +
    //    "  </div>\n" +
    //    "</div>\n" +
    //    "");

    $rootScope.user = fcAuth.user;

    $rootScope.guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    $window.angularize = function(element) {
        var newScope = $rootScope.$new();
        $compile(element)(newScope);
    };
};

// ReSharper restore InconsistentNaming
