'use strict';

if (typeof String.prototype.startsWith != 'function') {
    String.prototype.startsWith = function (str) {
        return this.slice(0, str.length) == str;
    };
}

if (typeof String.prototype.endsWith != 'function') {
    String.prototype.endsWith = function (str) {
        return this.slice(-str.length) == str;
    };
}

if (typeof String.prototype.trim != 'function') {
    String.prototype.fulltrim = function () {
        return this.replace(/(?:(?:^|\n)\s+|\s+(?:$|\n))/g, '').replace(/\s+/g, ' ');
    };
}

if (typeof String.prototype.trunc != 'function') {
    String.prototype.trunc = function (n) {
        return this.substr(0, n - 1) + (this.length > n ? '...' : '');
    };
}

if (typeof String.prototype.toOptions != 'function') {
    String.prototype.toOptions = function(defaults) {
        var opts = defaults ? defaults : {};
        var s = this.trim();
        if (s == '') return opts;
        var parts = s.split(';');
        if (!parts) return opts;
        for (var i = 0; i < parts.length; i++) {
            if (!parts[i]) continue;
            var subParts = parts[i].split(':');
            if (!subParts) continue;
            opts[subParts[0].trim()] = subParts[1].trim();
        }
        return opts;
    };
}

if (typeof Array.prototype.findMember != 'function') {
    Array.prototype.findMember = function(key, keyValue, member) {
        for (var i = 0; i < this.length; i++) {
            if (this[i][key] == keyValue) {
                return this[i][member];
            }
        }
        return '';
    }
}

if (typeof Number.prototype.toFriendlySize != 'function') {
    Number.prototype.toFriendlySize = function (precision) {
        var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

        var bytes = this;
        if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
            return '?';
        }

        var unit = 0;

        while (bytes >= 1024) {
            bytes /= 1024;
            unit++;
        }

        return bytes.toFixed(+precision) + ' ' + units[unit];
    };
}
