'use strict';

module.exports = function($http, $rootScope, $log, fcConfig) {
    var user = {
        isLoggedIn: false,
        role: 'none',
        name: 'None',
    };

    $http.get(fcConfig.apiUrl + '/auth', {
            headers: {
                'X-NoRedirect': 'true'
            }
        })
        .success(function(data) {
            user.isLoggedIn = data.isLoggedIn;
            user.name = data.name;
            user.email = data.email;
            user.gravatarUri = data.gravatarUri;
            user.role = data.role;
            user.isLoaded = true;
            user.continue = data.continue;

            $rootScope.$broadcast('auth-success', user);
        })
        .error(function(x, y, z) {
            $log.error(x);
        });

    return {
        user: user
    };
};
