"use strict";

// ReSharper disable InconsistentNaming

// gridmultiselect_jh

module.exports = function() {
    return {
        restrict: 'EA',
        require: '^fcGrid',
        replace: true,
        scope: {
            index: '@'
        },
        template: '<input ng-show="checkBoxes" type="checkbox" ng-model="checkBoxes[index]" ng-click="select()" />',
        link: MultiSelectLink
    };

    function MultiSelectLink(scope, elems, attrs, gridCtrl) {
        gridCtrl.initMultiSelect();
        var vm = scope;
        vm.$on('grid-clear-checkboxes', function() { init(); });
        if (vm.index == 'all') vm.$on('grid-refresh-all-checkbox', function() { init(); });
        init();

        vm.select = function() {
            if (vm.index == 'all') gridCtrl.selectAll();
            gridCtrl.checkAllSelected();
        };

        function init() { vm.checkBoxes = gridCtrl.checkBoxes; }
    }
};
// ReSharper restore InconsistentNaming