"use strict";

// ReSharper disable UseOfImplicitGlobalInFunctionScope

(function() {
    angular.module('widgets.framework')
        .directive('fcModalForm', fcModalFormDirective);

    function fcModalFormDirective(fcForms) {
        return {
            restrict: 'EA',
            transclude: true,
            template: [
                '<div data-ng-if="_mf.item" class="modal" style="display: block">',
                '   <div class="modal-dialog" ng-class="{\'modal-lg\':_mf.large}">',
                '       <div class="modal-content">',
                '           <div class="modal-header">',
                '               <div prev-next class="pull-right" ng-show="_mf.grid && _mf.item.id"></div>',
                '               <h3 class="modal-title">{{_mf.title}}</h3>',
                '           </div>',
                '           <form class="form-horizontal" novalidate name="{{_mf.formName}}" ng-submit="_mf.save()">',
                '               <div class="modal-body flds">',
                '                   <div fc-msgbox msg="_mf.msg" msg-type="_mf.msgType" msg-details="_mf.msgDetails"></div>',
                '                   <div ng-transclude></div>',
                '               </div>',
                '               <div class="modal-footer">',
                '                   <div class="row">',
                '                       <div class="col-sm-6">',
                '                           <div class="small text-muted" ng-show="_mf.item.updatedAt || _mf.item.updatedBy" style="text-align:left;margin-top: 10px">Updated {{_mf.item.updatedAt|date:\'short\'}} by {{_mf.item.updatedBy}}</div>',
                '                       </div>',
                '                       <div class="col-sm-6">',
                '                           <button type="submit" class="btn btn-primary fc-btn" data-loading="&lt;span class=&quot; glyphicon glyphicon-save&quot;&gt;&lt;/span&gt;&nbsp;Saving..."><span class="glyphicon glyphicon-save"></span>&nbsp;Save</button>',
                '                           <button type="button" class="btn btn-default fc-btn" data-loading="&lt;span class=&quot; glyphicon glyphicon-trash&quot;&gt;&lt;/span&gt;&nbsp;Deleting..." ng-click="_mf.delete()" ng-show="_mf.deleteEnabled && _mf.grid && _mf.item.id"><span class="glyphicon glyphicon-trash"></span>&nbsp;Delete</button>',
                '                           <button type="button" class="btn btn-link" data-ng-click="_mf.close()">Close</button>',
                '                       </div>',
                '                   </div>',
                '               </div>',
                '           </form>',
                '       </div>',
                '   </div>',
                '</div>'
            ].join(''),
            link: function(scope, element, attrs) {
                var mf = {
                    title: attrs.title,
                    large: attrs.large,
                    formName: attrs.fcModalForm || attrs.name,
                    itemName: attrs.item || 'item',
                    grid: attrs.mode != 'detail',
                    deleteEnabled: attrs.mode != 'detail' && attrs.mode != 'static-grid',
                    fm: null,
                    success: false,

                    init: function() {
                        if (mf.fm) return;

                        //console.log('mf.init: scope=%o', scope);

                        var nm = mf.formName;
                        mf.fm = fcForms.findCtrl(scope.$parent.$parent, nm);
                    },

                    reset: function(success) {
                        //console.log('mf.reset(%s)', success);
                        mf.init();
                        if (mf.fm) {
                            mf.fm.$setPristine();
                        }
                        mf.msg = success;
                        if (success) {
                            mf.msg = success + ' successfully!';
                            mf.msgType = 'success';
                        } else {
                            mf.msg = null;
                            mf.msgType = null;
                        }
                    },

                    delete: function() {
                        if (confirm('Are you sure you want to DELETE this?')) {
                            scope.delete().then(function() {
                                //console.log('mf.delete');
                                scope.$state.reload();
                                mf.reset();
                                scope.close();
                            });
                        }
                    },

                    close: function() {
                        //console.log('mf.close: scope=%o; itemName=%s', scope, mf.itemName);
                        mf.reset();
                        scope.close();
                    },

                    save: function() {
                        //console.log('mf.save');
                        scope.save().then(function() {
                            scope.$state.reload();
                            mf.reset();
                            scope.close();
                        });
                    }
                };
                scope._mf = mf;

                scope.$watch(mf.itemName, function(newValue, oldValue) {
                    mf.item = newValue;

                    if (newValue && !oldValue) {
                        mf.reset();
                    }
                });
            }
        };
    };

})();

// ReSharper restore UseOfImplicitGlobalInFunctionScope
