'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function($http, $window) {
    return {
        scope: {
            options: '@fcCart'
        },
        template: '<div class="fc fc-widget fc-cart"><div data-ng-include="view"></div></div>',
        controller: CartCtrl,
        link: CartLink
    };

    function CartCtrl($scope, $location, $controller, $filter, fcUrls, fcConfig, fcAuth, $analytics, $sce) {
        var vm = $scope;
        $controller('fcCtrl', { $scope: $scope });

        vm.views = {
            a: 'tmpl/widgets/cart/cart.html',
            b: 'tmpl/widgets/cart/shippingPromo.html',
            c: 'tmpl/widgets/cart/review.html',
            d: 'tmpl/widgets/cart/thankyou.html',
            e: 'tmpl/widgets/cart/paymenterror.html',
            f: 'tmpl/widgets/cart/paymentdeclined.html',
            g: 'tmpl/widgets/cart/userinfo.html',
            h: 'tmpl/widgets/cart/guesttimeout.html',
            i: 'tmpl/widgets/cart/paymentiframe.html'
        };

        var apiKey = fcConfig.apiKey;

        vm.view = vm.views.a;
        vm.cart = {};
        vm.digitalOnly = true;
        vm.physicalOnly = true;
        vm.curbstoneSrc = '';
        
        var v = fcUrls.location().params.v;
        if (v) vm.view = vm.views[v];

        vm.optIn = { emailOptIn: true };

        vm.logOut = function () {
            window.__SUSPEND_ENGINE_STATUS__ = true;
            $http['delete'](fcConfig.apiUrl + '/auth')
                .success(function (data) {
                    $window.top.location = data.continue;
                    $window.top.location.reload();
                });
        };

        vm.OrderCompleteEcommerceTrackTransaction = function () {
            var orderId = fcUrls.location().params.orderId;
            var billingName = fcUrls.location().params.billingName;
            if (orderId) {
                $http.get(fcConfig.apiUrl + '/orders/' + orderId + '/name/' + billingName)
                    .success(function (data) {
                        $analytics.ecommerceTrack('OrderComplete_Thanks', {
                            category: 'Cart',
                            order: data,
                            location: window.location
                        });
                    });
            }
        }

        vm.PaymentDeclined = function() {
            if (!(vm.cart && vm.cart.items)) {
                $http.get(fcConfig.apiUrl + '/cart').success(function(data) {
                    vm.loadCart(data);
                    $analytics.eventTrack('Payment_Declined', {
                        category: 'Cart'
                    });
                    $analytics.ecommerceTrack('Payment_Declined', {
                        category: 'Cart',
                        cart: vm.cart,
                        location: window.location
                    });
                });
            }
        }

        vm.PaymentError = function () {
            if (!(vm.cart && vm.cart.items)) {
                $http.get(fcConfig.apiUrl + '/cart').success(function (data) {
                    vm.loadCart(data);
                    $analytics.eventTrack('Payment_Error', {
                        category: 'Cart'
                    });
                    $analytics.ecommerceTrack('Payment_Error', {
                        category: 'Cart',
                        cart: vm.cart,
                        location: window.location
                    });
                });
            }
        }

        vm.timeOut = function () {
            $analytics.eventTrack('Guest_Clear', { category: 'Cart' });

            window.__SUSPEND_ENGINE_STATUS__ = true;
            $http['delete'](fcConfig.apiUrl + '/auth')
                .success(function (data) {
                    // Ultimately QA wants this to give UI notice that a timeout/logout has occurred but it's problematic since unauthing then switching views 
                    //  without a full redirect will cause acccess denied issues. 
                    //.view = vm.views['h'];
                    //$window.top.location = data.continue;
                    //$window.top.location.reload();
                });
        };

        vm.loadCart = function(data) {
            vm.cart = data;
            vm.core.cartItemCount = data.itemCount;

            // TODO This will also time out the user when they remove all items from a guest cart.
            if (vm.auth.user.role === 'guest' && vm.core.cartItemCount === 0) {
                vm.timeOut();
            }

            if (vm.cart && vm.cart.shippingOptions) {
                for (var i = 0; i < vm.cart.shippingOptions.length; i++) {
                    var opt = vm.cart.shippingOptions[i]; 
                    opt.displayDescription = opt.description + '  ' + $filter('currency')(opt.price, opt.currency);
                }
            }
            
            if (vm.cart && vm.cart.items) {
                for (var j = 0; j < vm.cart.items.length && vm.digitalOnly; j++) {
                    var format = vm.cart.items[j].format ? vm.cart.items[j].format.toUpperCase() : null;
                    if (!format || (format != 'DIGITAL' && format != 'SUBSCRIPTION')) {
                        vm.digitalOnly = false;
                    }
                }
            }

            if (vm.optInUncheck) {
                vm.optIn.emailOptIn = false;
            }

            vm.displayDrmLabel = vm.displayDrmLabel && vm.auth.user.role !== 'guest' ? true : false;
            vm.showOptInMail = vm.showOptInMail;

        };

        vm.getCart = function() {
            $http.get(fcConfig.apiUrl + '/cart').success(vm.loadCart);
        };

        vm.getCurbstoneSrc = function () {
            $http.get(fcConfig.apiUrl + '/cart/payment/uri')
                .success(function (data) {
                    if (data && data.length > 0) {
                        vm.curbstoneSrc = $sce.trustAsResourceUrl(data);
                    }
                });
        };

        vm.getMailListUrl = function() {
            $http.get(fcConfig.apiUrl + '/app/maillist')
                .success(function(data) {
                    if (data && data.length > 0) {
                        vm.mailListUrl = data;
                    } else {
                        vm.mailListUrl = null;
                    }
                });
        };

        vm.updateItem = function (item) {
            if (item.quantity <= 0) {
                vm.removeItem(item);
            } else {
                $http.put(fcConfig.apiUrl + '/cart', item)
                    .success(function (data) {
                        $analytics.eventTrack('Item_Update', {
                            category: 'Cart',
                            label: 'ean: ' + item.ean.toString() + ' qty: ' + item.quantity.toString(),
                            cartItem: item
                        });
                        $analytics.ecommerceTrack('Item_Update', {
                            category: 'Cart',
                            item: item,
                            location: window.location
                        });
                        vm.loadCart(data);
                    });
            };
        };

        vm.removeItem = function (item) {
            $http['delete'](fcConfig.apiUrl + '/cart/' + item.ean)
                .success(function (data) {
                    $analytics.eventTrack('Item_Remove', { category: 'Cart', label: 'ean: ' + item.ean.toString() });
                    $analytics.ecommerceTrack('Item_Remove', { category: 'Cart', item: item });
                    vm.loadCart(data);
                });
        };

        vm.checkout = function () {
            if (vm.cart && vm.cart.items) {
                for (var j = 0; j < vm.cart.items.length; j++) {

                    if (!vm.cart.items[j].format || vm.cart.items[j].format.toUpperCase() != 'PHYSICAL') {
                        vm.physicalOnly = false;
                    }
                }
            }
            $analytics.eventTrack('Checkout_Click', { category: 'Cart', label: 'curItemCount: ' + vm.cart.items.length.toString() + ' physicalProductOnly: ' + vm.physicalOnly.toString() });
            $analytics.ecommerceTrack('Checkout_Click', { category: 'Cart', cart: vm.cart, location: window.location });

            if (vm.physicalOnly == false && fcAuth.user.role == 'guest') {
                // Can not proceed with digital purchase as a guest... 
                vm.view = vm.views['b'];
            }
            else if (fcAuth.user.role != 'none') {
                vm.view = vm.views['b'];
            } else {
                // force login when not all physical.
                if (vm.physicalOnly) {
                    vm.view = vm.views['g'];
                } else {
                    fcUrls.go('/uri/cart/checkout');
                }
            }
        };

        vm.continueShopping = function () {
            //$analytics.eventTrack('Continue_Click', { category: 'Cart', label: 'curItemCount: ' + vm.cart.items.length.toString() });
            fcUrls.go('/uri/home');
        };

        vm.next = function (view) {
            //$analytics.eventTrack('WizardNext_Click', { category: 'Cart', label: 'View: ' + vm.views[view].toString() });
            vm.loadCart();
            vm.view = vm.views[view];
        };

        vm.cancel = function () {
            //$analytics.eventTrack('Cancel_Click', { category: 'Cart', label: 'View: ' + vm.views[view].toString() });
            fcUrls.go('/uri/cart');
        };

        vm.gotoShipping = function() {
            vm.getCart();
            vm.showShippingOptions = true;
        };

        vm.updateShipping = function () {
            //$analytics.eventTrack('UpdateShipping_Click', { category: 'Cart' });
            $http.post(fcConfig.apiUrl + '/cart', vm.cart)
                .success(function () {
                    $analytics.ecommerceTrack('Shipping_Promo_Code', { category: 'Cart', cart: vm.cart, location: window.location });
                    vm.getCart();
                    vm.view = vm.views.c;
                });
        };

        vm.historyBack = function() {
            $window.history.back();
        };

        vm.makePayment = function () {
            //$analytics.eventTrack('MakePayment_Click', { category: 'Cart' });
            if (vm.showOptInMail) {
                $http.post(fcConfig.apiUrl + '/cart/email/subscription', vm.optIn)
                    .success(function () {
                        $analytics.ecommerceTrack('ReviewOrderPage', { category: 'Cart', cart: vm.cart, location: window.location });
                        vm.getCurbstoneSrc();
                        vm.view = vm.views.i;
                    });
            }
            else {
                $analytics.ecommerceTrack('ReviewOrderPage', { category: 'Cart', cart: vm.cart, location: window.location });
                vm.getCurbstoneSrc();
                vm.view = vm.views.i;
            }
        };
    }

    function CartLink(scope) {
        scope.getCart();

        var reviewUrls = {
            default: {
                ade: "http://mitpress.mit.edu/our-ebooks#installation",
                bluefire: "http://mitpress.mit.edu/our-ebooks#installation",
                aldiko: "http://mitpress.mit.edu/our-ebooks#installation"
            },
            hup: {
                ade: "http://www.adobe.com/solutions/ebook/digital-editions.html",
                bluefire: "http://www.bluefirereader.com/",
                aldiko: "http://www.aldiko.com/"
            }
        };

        scope.$watch('options', function (newValue) {
            if (newValue) {
                var opts = newValue.toOptions();
                scope.displayDrmLabel = opts.drmLabel == 'false' ? false : true;
                scope.showOptInMail = opts.optInMail == 'true' ? true : false;
                scope.optInUncheck = opts.optInUncheck == 'true' ? true : false;
                if (opts.hupCart == 'true') {
                    scope.hupCart = true;
                    scope.reviewUrls = reviewUrls.hup;
                } else {
                    scope.hupCart = false;
                    scope.reviewUrls = reviewUrls.default;
                }
                if (opts.optInLabel && opts.optInLabel.length > 0) {
                    scope.optInLabel = opts.optInLabel;
                }
                if (scope.showOptInMail) {
                    scope.getMailListUrl();
                }
            }
        });
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
