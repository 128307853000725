'use strict';

module.exports = function() {
    return {
        require: '?^form',
        restrict: 'C',
        scope: {
            loading: '@'
        },
        link: function(scope, element, attrs, formCtrl) {
            var initializing = true;

            function getForm(el) {
                var p = el.parent();
                if (p.length <= 0) return null;
                if (p[0].nodeName == 'FORM') return p;
                return getForm(p);
            }

            function reset() {
                if (scope.resetHtml) {
                    element.html(scope.resetHtml);
                }
                if (scope.disabledFromLoad) {
                    enable();
                } else if (type == 'submit' && formCtrl && (formCtrl.$invalid || formCtrl.$pristine)) {
                    disable();
                } else {
                    enable();
                }

                scope.clicked = false;
            }

            function handleClick() {
                scope.$root.$broadcast('fc-btn-init');
                scope.clicked = true;
            }

            function load() {
                if (scope.loading && scope.clicked) {
                    if (scope.disabledFromLoad) {
                        scope.resetHtml = element.html();
                        element.html(scope.loading);
                    }
                }
            }

            function disable(fromLoad) {
                scope.disabledFromLoad = fromLoad;
                element.attr('disabled', 'disabled');
            }

            function enable() {
                scope.disabledFromLoad = false;
                element.removeAttr('disabled');
            }

            function initSubmit(fm) {

                fm.on('submit', handleClick);

                function updateDisable() {
                    if (scope.disabledFromLoad) {
                        return;
                    }
                    if (formCtrl.$invalid || formCtrl.$pristine) {
                        disable();
                    } else {
                        enable();
                    }
                }

                var formName = formCtrl.$name;
                var invalidExp = [formName, '$invalid'].join('.');
                var pristineExp = [formName, '$pristine'].join('.');

                var fmScope = scope.$parent;

                while (!fmScope[formName]) {
                    fmScope = fmScope.$parent;
                    if (!fmScope) break;
                }

                fmScope.$watch(invalidExp, updateDisable);
                fmScope.$watch(pristineExp, updateDisable);

                updateDisable();
            }

            scope.$on('loading-started', function() {
                if (initializing) return;

                disable(true);
                load();
            });

            scope.$on('loading-stopped', function() {
                if (initializing) return;
                reset();
            });

            scope.$on('fc-btn-init', function() {
                scope.clicked = false;
            });

            var type = element.attr('type');
            if (type == 'submit') {
                var form = getForm(element);
                if (form) initSubmit(form);
            } else {
                element.on('click', handleClick);
            }

            initializing = false;
        }
    };
};
