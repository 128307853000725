'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function ($http, $window, fcUrls, fcConfig) {
    return {
        restrict: 'EA',
        scope: {},
        templateUrl: 'tmpl/widgets/content/form/huploebfreetrial.html',
        link: HupLoebFreeTrialLink
    };

    function HupLoebFreeTrialLink(scope) {
        var vm = scope;

        vm.load = load;
        vm.send = send;

        load();

        function load() {
            vm.submitClicked = false;
            vm.formData = {};
        };

        function send() {
            if (!vm.submitClicked) {
                var captchaResponse = grecaptcha.getResponse();
                if (captchaResponse && captchaResponse.length > 0) {
                    vm.submitClicked = true;
                    var postUrl = fcConfig.apiUrl + '/forms/generic/loebtrial';
                    var parms = vm.formData;
                    var apiParms = [];
                    for (var p in parms) {
                        if (!parms.hasOwnProperty(p)) continue;
                        if (!parms[p]) continue;
                        apiParms.push(p + '=' + parms[p]);
                    }
                    apiParms.push('grecaptcha=' + captchaResponse);
                    postUrl = postUrl + (apiParms.length > 0 ? '?' + apiParms.join('&') : '');

                    $http.post(postUrl).success(function(data) {
                            var uri = data.uri;
                            vm.captchaError = !data.captchaSuccess;
                            if (!vm.captchaError) {
                                $window.top.location = 'DigitalEditionFreeTrialConfirm';
                            }
                            else {
                                grecaptcha.reset();
                            }
                            vm.submitClicked = false;
                        })
                        .error(function(data) {
                            if (!data) return;
                            vm.captchaError = !data.captchaSuccess;
                            vm.submitClicked = false;
                            grecaptcha.reset();
                        });
                }
                else {
                    vm.captchaError = true;
                }
            }
        };
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
