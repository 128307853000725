'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

var Cookies = require('cookies-js');

module.exports = function ($http, fcAuth, $sce, $window, fcUrls, fcConfig, fcHtmlUtils, $anchorScroll, $log, $compile, $timeout) {
    //fcConfig.locationEnabled = true; // Content widget requires $location to be turned on.

    return {
        restrict: 'EA',
        scope: {
            lapp: '@',
            type: '@',
            name: '@',
            titleformat: '@',
            manage: '@',
            liveLink: '@',
            embed: '@',
            overwritetitle: '@'
        },
        templateUrl: 'tmpl/widgets/content/content.html',
        link: ContentLink
    };

    function ContentLink(scope, element, attrs) {
        var vm = scope,
            apiUrl = fcConfig.apiUrl,
            parms = fcUrls.location().params,
            contentPath = '/content/' + (vm.lapp ? vm.lapp + '/' : '') + vm.type + '/' + vm.name,
            contentUri = apiUrl + contentPath,
            liveUri = apiUrl + '/uri' + contentPath,
            imageUploadUri = apiUrl + '/content/files',
            uploadUri = apiUrl + '/content/files?preserveFileName=true&overwriteExisting=true',
            wgt = element.find('.content'),
            appId = scope.$root.appId,
            token = Cookies.get(appId + '-token');

        vm.edit = edit;
        vm.save = save;
        vm.cancel = cancel;
        vm.live = live;
        vm.init = init;
        vm.load = load;
        vm.setHtmlOnly = setHtmlOnly;
        vm.isLoaded = false;

        vm.$on('auth-success', authSuccess);
        vm.$on('content-' + vm.name + '-save', save);

        init();

        function init() {
            var role = fcAuth.user.role,
                canEdit = role == 'publisher' || role == 'admin',
                managing = (Boolean(parms.manage) || Boolean(vm.manage)) && canEdit,
                embedded = Boolean(vm.embed);

            vm.managing = managing;
            vm.embedded = embedded;
            vm.htmlOnly = false;

            if (fcAuth.user.isLoaded && !vm.isLoaded) {
                vm.isLoaded = true;
                load();
            }
        }

        function load() {
            $http.get(contentUri)
                .success(function(data) {
                    vm.content = data;
                    if (data && data.html) {
                        vm.htmlOnly = Boolean(data.htmlOnly);
                        vm.html = $sce.trustAsHtml(data.html);
                        vm.fullHtml = $sce.trustAsHtml(data.fullHtml);

                        appendMeta(vm.content.metaTags);

                        var htmlContainer = vm.managing 
                            ? element.find('.fc-content-managed-html')
                            : element.find('.fc-content-html');
                        htmlContainer.html(data.fullHtml);
                        $compile(htmlContainer.contents())(vm);
                    } else {
                        vm.html = null;
                        vm.fullHtml = null;
                    }
                    if (vm.embedded) {
                        $timeout(function () {
                            edit();
                        });
                    }

                    if (vm.overwritetitle) fcHtmlUtils.formatHtmlTitle(vm.titleformat ? vm.titleformat : 'hup', data);

                    // Klugey way to keep the cloud content type in check with the TMMWeb type value. 
                    if (vm.type && data.type) {
                        if (vm.type.toLowerCase() !== data.type.toLowerCase()) {
                            // create a Post call to set the proper value.
                            $http.post(contentUri, {
                                html: vm.content.html,
                                htmlOnly: vm.htmlOnly,
                                type: vm.type,
                                app: vm.lapp
                            })
                                .success(function (data) {
                                    var name = data.name;
                                    var type = data.type;
                                //vm.content = data;
                                //vm.html = $sce.trustAsHtml(data.html);
                                //vm.fullHtml = $sce.trustAsHtml(data.fullHtml);
                                //cancel();
                                //load();
                            });
                        }
                    }

                    $timeout(function() {
                        var hash = fcUrls.location().hash;
                        if (hash) {
                            var elem = document.getElementById(hash);
                            if (elem) window.scrollTo(0, elem.offsetTop);
                        }
                    });
                });
        }

        function live(manage) {
            var opts = manage ? '?manage=true' : '';
            $http.get(liveUri + opts)
                .success(function(data) {
                    $window.open(data.uri, '_blank');
                });
        }

        function authSuccess(e, user) {
            init();
        }

        function edit() {
            vm.editing = true;
            !vm.htmlOnly && wgt && wgt.editable && wgt.editable({
                key: vm.content.license,
                theme: 'fbt',
                minHeight: 700,
                maxHeight: 700,
                inlineMode: false,
                imageUploadURL: imageUploadUri,
                fileUploadURL: uploadUri,
                allowStyle: true,
                allowScript: true,
                allowComments: true,
                headers: {
                    Authorization: 'Bearer ' + token,
                    'X-Fcs-App': appId
                },
                buttons: [
                    'bold',
                    'italic',
                    'underline',
                    'strikeThrough',
                    'sep',
                    'subscript',
                    'superscript',
                    'color',
                    'formatBlock',
                    'align',
                    'insertOrderedList',
                    'insertUnorderedList',
                    'outdent',
                    'indent',
                    'sep',
                    'createLink',
                    'insertImage',
                    'insertVideo',
                    'uploadFile',
                    'sep',
                    'table',
                    'undo',
                    'redo']
            });
            if (!vm.htmlOnly) {
                wgt.editable('option', 'maxFileSize', 1024 * 1024 * 20); //default to 20MB
                var allowedTags = wgt.editable('option', 'allowedTags');
                allowedTags.push('carousel');
                allowedTags.push('slide');
                allowedTags.push('fc-content');
                allowedTags.push('fc-bookmark');
                allowedTags.push('include');
                var allowedBlankTags = wgt.editable('option', 'allowedBlankTags');
                allowedBlankTags.push('carousel');
                allowedBlankTags.push('slide');
                allowedBlankTags.push('fc-content');
                allowedBlankTags.push('include');
                var allowedAttrs = wgt.editable('option', 'allowedAttrs');
                allowedAttrs.push('ng-href');
                allowedAttrs.push('ng-src');
                allowedAttrs.push('interval');
                allowedAttrs.push('slide');
            }
        }

        function cancel(onlyHtml) {
            if (typeof onlyHtml === 'undefined') {
                onlyHtml = vm.htmlOnly;
            }
            vm.editing = false;
            !onlyHtml && wgt && wgt.editable && wgt.editable && wgt.editable('destroy');
        }

        function bookmark(id) {
            var elem = document.getElementById(id);
            if (elem) {
                $log.debug('bookmark: ' + id);
                window.scrollTo(0, elem.offsetTop);
            }
        }

        function save() {
            var content = '';
            if (!vm.htmlOnly) {
                content = wgt.editable('getHTML');
            }
            else {
                content = vm.content.html;
            }
            $http.post(contentUri, {
                    html: content,
                    htmlOnly: vm.htmlOnly
                })
                .success(function(data) {
                    vm.content = data;
                    vm.html = $sce.trustAsHtml(data.html);
                    vm.fullHtml = $sce.trustAsHtml(data.fullHtml);
                    cancel();
                    load();
                });
        }

        function setHtmlOnly() {
            cancel(!vm.htmlOnly);
            edit();
        }

        function appendMeta(myTags) {
            if (myTags) {
                var existTags = document.getElementsByTagName('meta');
                if (existTags && existTags.length > 0) {
                    for (var i = 0; i < myTags.length; i++) {
                        var myName = myTags[i].name;
                        var myContent = myTags[i].content;
                        var myProperty = myTags[i].property;
                        if (myName && myName.length > 0 && myContent && myContent.length > 0) {
                            var foundTag = false;
                            var metaTag = null;
                            for (var j = 0; j < existTags.length && !foundTag; j++) {
                                if (myName == existTags[j].name) {
                                    metaTag = existTags[j];
                                    foundTag = true;
                                }
                            }
                            if (!metaTag) {
                                metaTag = document.createElement('meta');
                                metaTag.name = myName;
                            }
                            metaTag.content = myContent;
                            if (myProperty && myProperty.length > 0) {
                                metaTag.property = myProperty;
                            }
                            if (!foundTag) {
                                document.getElementsByTagName('head')[0].appendChild(metaTag);
                            }
                        }
                    }
                }
            }
        };


    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals