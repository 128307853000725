'use strict';

// ReSharper disable InconsistentNaming
module.exports = function () {

    return {
        restrict: 'E',
        require: '^fcMultiUpload',
        scope: {
            name: '@',
            label: '@',
            viewTemplateUrl: '@',
            selectTemplateUrl: '@'
        },
        template: '<div></div>',
        link: MultiUploadMetadataItemLink
    };

    function MultiUploadMetadataItemLink(scope, element, attrs, ctrl) {
        var vm = scope;
        ctrl.addMetadataItem({
            name: vm.name,
            label: vm.label,
            viewTemplateUrl: vm.viewTemplateUrl,
            selectTemplateUrl: vm.selectTemplateUrl
        });
    }
};
// ReSharper restore InconsistentNaming
