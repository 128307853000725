'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function ($http, fcConfig) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.bind('load', function () {
                //call the function that was passed
                scope.$apply(attrs.imageOnLoad);
            });
        }
    };
};