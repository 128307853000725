'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function($http, $templateCache, $compile, fcConfig, fcUrls) {

    return {
        scope: {
            options: '@fcLibrary'
        },
        controller: LibraryCtrl,
        link: LibraryLink
    };

    function LibraryCtrl($scope, $controller) {
        $controller('fcCtrl', {
            $scope: $scope
        });
        $scope.items = {};

        $scope.columnClass = 'col-sm-2';
        $scope.templateUrl = 'tmpl/widgets/library/library.grid.popover.html';

        $scope.loadItems = function(items) {
            var rows = [],
                listItems = [],
                utcDateTime,
                rowIndex,
                colIndex,
                colsFromRight,
                apiKey = $scope.$root.apiKey;


            if ($scope.columns == 1) $scope.columnClass = 'col-sm-3';
            else if ($scope.columns == 2) $scope.columnClass = 'col-sm-3';
            else if ($scope.columns == 3) $scope.columnClass = 'col-sm-2';
            else if ($scope.columns == 4) $scope.columnClass = 'col-md-2 col-sm-3';
            else if ($scope.columns == 5 || $scope.columns == 6) $scope.columnClass = 'col-sm-2';
            else if ($scope.columns >= 7) $scope.columnClass = 'col-sm-1';

            for (var i = 0; i < items.length; i++) {

                utcDateTime = new Date(items[i]['createdAt']);
                items[i]['purchaseDate'] = (utcDateTime.getMonth() + 1) + "/" + (utcDateTime.getDate()) + "/" + (utcDateTime.getFullYear());

                listItems.push(items[i]);

                // group items into rows for the grid view
                rowIndex = Math.floor(i / $scope.columns);
                colIndex = i % $scope.columns;
                colsFromRight = Math.min($scope.columns - colIndex, items.length - colIndex) - 1;

                if (colsFromRight >= 2 || colIndex == 0) {
                    items[i].popoverPlacement = "right";
                } else {
                    items[i].popoverPlacement = "left";
                }

                //var popoverBody = '<h4>' + items[i].title + '<br/><small>' + items[i].authors + '</small></h4>';
                //popoverBody += items[i].description || '';
                //popoverBody += '<hr/>';

                ////popoverBody += // The download button(s)
                ////    '<button style="margin-top: 3px" class="btn btn-default btn-sm btn-block" ' +
                ////    'data-ng-show="hasDownloadButton" data-ng-repeat="asset in item.assets" ' +
                ////    'data-ng-click="download(asset.downloadUrl)">Download {{asset.type}}</button>';

                //for (var k = 0; k < items[i].assets.length; k++) {
                //    var downloadUrl = items[i].assets[k].downloadUrl;

                //    popoverBody += // popover has an issue with angular-js, using angularless js code for download
                //        '<button style="margin-top: 3px" class="btn btn-default btn-block" onclick="fcDownload(\'' + downloadUrl + '\')">' +
                //        '<i class="glyphicon glyphicon-download"></i> Download ' + items[i].assets[k].type + '</button>';
                //}
                ////popoverBody += "</div>";
                //items[i].popoverContent = popoverBody;
                //items[i].popoverTrigger = "click";
                if ($scope.columns <= 2) items[i].thumbUrl = items[i].coverUrl;

                if (rows.length < (rowIndex + 1)) {
                    rows[rowIndex] = [];
                }
                rows[rowIndex].push(items[i]);
            }
            $scope.rows = rows;
            $scope.listItems = items;
        };

        $scope.rows = [];
        $scope.listItems = [];

        $scope.load = function() {
            $scope.loading = true;
            $http.get(fcConfig.apiUrl + '/library')
                .then(function(result) {
                    $scope.loadItems(result.data);
                    $scope.loading = false;
                })
                .catch(function(e) {
                    $scope.loading = false;
                });
        };

        $scope.download = function (asset) {
            fcUrls.go(asset.downloadUrl, true);
        };

        $scope.downloadLabel = function(asset) {
            switch (asset.typeTag) {
                case 'CLD_AT_Epub':
                case 'CLD_AT_EDrmEpub':
                case 'CLD_AT_EDrmEpub_Auto':
                    return 'Download DRM-free ePub';

                case 'CLD_AT_WebPdf':
                case 'CLD_AT_PrintPdf':
                case 'CLD_AT_EDrmPdf':
                case 'CLD_AT_EDrmPdf_Auto':
                    return 'Download DRM-free PDF';

                case 'CLD_AT_Kindle':
                    return 'Download for Kindle';

                default:
                    return 'Download ' + asset.type;
            }
        }
    }

    function LibraryLink(scope, element, attrs, ctrl) {

        var templateUrl;

        scope.$watch('options', function(newValue) {
            if (newValue) {
                var opts = newValue.toOptions({
                    hasTitle: true,
                    hasAuthor: true,
                    hasDownloadButton: true,
                    hasPopOver: true,
                    columns: 4,
                    view: "grid"
                });
                scope.hasTitle = opts.hasTitle == 'true' ? true : false;
                scope.hasAuthor = opts.hasAuthor == 'true' ? true : false;
                scope.hasDownloadButton = opts.hasDownloadButton == 'true' ? true : false;
                scope.hasPopOver = opts.hasPopOver == 'true' ? true : false;
                scope.columns = parseInt(opts.columns);
                scope.view = opts.view;

                templateUrl = 'tmpl/widgets/library/library.' + scope.view + ".html";

                $http
                    .get(templateUrl, {
                        cache: $templateCache
                    })
                    .success(function(templateContent) {
                        element.replaceWith($compile(templateContent)(scope));
                    });

                scope.load();
            }
        });
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
