'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function ($http, $window, fcUrls, fcConfig) {
    return {
        restrict: 'EA',
        scope: {},
        templateUrl: 'tmpl/widgets/content/form/huppermission.html',
        link: HupPermissionLink
};

    function HupPermissionLink(scope) {
        var vm = scope;
        vm.load = load;
        vm.addRow = addRow;
        vm.removeRow = removeRow;
        vm.send = send;

        load();

        function load() {
            vm.submitClicked = false;
            vm.formData = { passages: [{}], illustrations: [{}], poetryLines: [{}], formats: [{}] };
        };

        function addRow(collection) {
            collection.push({});
        };

        function removeRow(collection) {
            if (collection.length > 0) {
                collection.splice(collection.length - 1, 1);
            }
        };

        function send() {
            if (!vm.submitClicked) {
                var captchaResponse = grecaptcha.getResponse();
                if (captchaResponse && captchaResponse.length > 0) {
                    vm.submitClicked = true;
                    var postUrl = fcConfig.apiUrl + '/forms/nongeneric/primaryPermissionForm';
                    var formData = vm.formData;
                    formData["CaptchaResponse"] = captchaResponse;

                    $http.post(postUrl, formData ).success(function (data) {
                            var uri = data.uri;
                            vm.captchaError = !data.captchaSuccess;
                            if (!vm.captchaError) {
                                $window.top.location = 'PermissionFormConfirm';
                            }
                            else {
                                grecaptcha.reset();
                            }
                            vm.submitClicked = false;
                        })
                        .error(function(data) {
                            if (!data) return;
                            vm.captchaError = !data.captchaSuccess;
                            vm.submitClicked = false;
                            grecaptcha.reset();
                        });
                }
                else {
                    vm.captchaError = true;
                }
            }
        };
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
