'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function ($http, fcUrls, fcConfig) {
    return {
        restrict: 'EA',
        scope: {
            category: '@',
            product: '@',
            lapp: '@'
        },
        templateUrl: 'tmpl/widgets/content/ad.html',
        link: AdLink
    };

    function AdLink(scope) {
        var vm = scope;

        vm.load = load;

        load();

        function load() {
            var parms = fcUrls.location().params;

            var apiParms = [];
            if (vm.lapp) parms.linkedApp = vm.lapp;
            for (var p in parms) {
                if (!parms.hasOwnProperty(p)) continue;
                if (!parms[p]) continue;
                apiParms.push(p + '=' + parms[p]);
            }
            var url = fcConfig.apiUrl + '/ads';
            if (vm.category && vm.category.length > 0) {
                url += '/categories/' + vm.category;
            }
            else if (vm.product && vm.product.length > 0) {
                url += '/products/' + vm.product;
            }
            url += (apiParms.length > 0 ? '?' + apiParms.join('&') : '');

            $http.get(url)
                .success(function (data) {
                    vm.item = data;
                    vm.name = vm.item.contentName;
            });
        };

    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals