"use strict";

// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        restrict: 'EA',
        require: '^fcGrid',
        scope: {
            load: '&',
            items: '='
        },
        template: '\
            <ul class="pagination pagination-sm">\
                <li analytics-on="click" analytics-event="prevPage_Click" analytics-category="Search" ng-class="prevPageClass()"><a ng-click="prevPage()"><span class="glyphicon glyphicon-chevron-left small"></span></a></li>\
                <li class="disabled "><span style="width: 160px; text-align-last: center; text-align: center;" ><strong>{{getStartingRecord()}}</strong>-<strong>{{getEndingRecord()}}</strong> of <strong>{{getTotalRecords()}}</strong></span></li>\
                <li analytics-on="click" analytics-event="nextPage_Click" analytics-category="Search" ng-class="nextPageClass()"><a ng-click="nextPage()"><span class="glyphicon glyphicon-chevron-right small"></span></a></li>\
            </ul>',
        link: PagingLink
    };

    function PagingLink(scope, elem, attrs, gridCtrl) {
        var vm = scope;
        vm.prevPage = function () { gridCtrl.prevPage(); };
        vm.prevPageClass = gridCtrl.prevPageClass;
        vm.nextPage = function () { gridCtrl.nextPage(); };
        vm.nextPageClass = gridCtrl.nextPageClass;
        vm.getStartingRecord = gridCtrl.getStartingRecord;
        vm.getEndingRecord = gridCtrl.getEndingRecord;
        vm.getTotalRecords = gridCtrl.getTotalRecords;
    }
};

// ReSharper restore InconsistentNaming