
'use strict';

module.exports = function() {
    return {
        restrict: 'EA',
        template: [
            '<div class="alert alert-{{msgType}}" data-ng-show="msg" style="word-wrap:break-word;" >',
            '<a class="alert-link" ng-click="detail=!detail">{{msg}}</a>',
            '<div ng-show="detail && msgDetails">{{msgDetails}}</div>',
            '</div>'
        ].join(''),
        scope: {
            msg: '=?',
            msgType: '=?',
            msgDetails: '=?'
        },
        link: function(scope) {
            scope.errorReset = function(data) {
                scope.msg = null;
                scope.msgType = null;
                scope.msgDetails = null;
            };

            scope.error = function(data) {
                var err = data.responseStatus;
                if (err) {
                    var msg = err.message.replace(/^.+\: /g, '');
                    scope.msg = msg;
                    scope.msgType = 'danger';
                    scope.msgDetails = err.stackTrace;
                    //alert(msg);
                } else {
                    scope.msg = 'Server Error';
                    scope.msgType = 'danger';
                    scope.msgDetails = data;
                    //alert('error');
                }
            };

            scope.$on('onErrorReset', function(e, data) {
                scope.errorReset(data);
            });

            scope.$on('onError', function(e, data) {
                scope.error(data);
            });
        }
    };
};
