module.exports = function() {
    return {
        restrict: 'EA',
        scope: {
            label: '@',
        },
        transclude: true,
        template: '<div ng-show="vertical"><div class="fld-label-vertical">{{label}}</div>' +
            '<div class="fld" ng-transclude></div></div>' +
            '<div ng-hide="vertical" class="row">' +
            '<div class="{{labelCol}} fld-label">{{label}}</div>' +
            '<div class="{{valueCol}} fld" ng-transclude></div>' +
            '</div>',
        link: function(scope, element, attrs, ctrl, transclude) {
            var size = attrs.fcFld || attrs.size;
            if (!size || size.indexOf(',') <= 0) {
                scope.vertical = true;
            } else {
                var sizes = size.split(',');
                scope.labelCol = 'col-sm-' + sizes[0];
                scope.valueCol = 'col-sm-' + sizes[1];
            }
            /*
            transclude(scope.$parent, function(clone) {
                element.append(clone);
            });*/
        }
    };
};  
