'use strict';
// ReSharper disable InconsistentNaming

module.exports = function ($document) {
    return {
        scope: { selectorElemId: '@' },
        link: DraggableLink
    };

    // Draggable directive from: http://docs.angularjs.org/guide/compiler
    function DraggableLink($scope, element) {
        var vm = $scope,
            draggableElem = element,
            selectorElem,
            startX = 0,
            startY = 0,
            x = 0,
            y = 0;
        if (vm.selectorElemId) selectorElem = angular.element(document.getElementById(vm.selectorElemId));
        else selectorElem = element;

        draggableElem.css({ position: 'fixed' });
        selectorElem.css({ cursor: 'move' });
        selectorElem.on('mousedown', function (event) { mouseDown(event); });

        function mouseDown(event) {
            // Prevent default dragging of selected content
            event.preventDefault();
            startX = event.screenX - x;
            startY = event.screenY - y;
            $document.on('mousemove', mouseMove);
            $document.on('mouseup', mouseUp);
        }

        function mouseUp() {
            $document.unbind('mousemove', mouseMove);
            $document.unbind('mouseup', mouseUp);
        }

        function mouseMove(event) {
            y = event.screenY - startY;
            x = event.screenX - startX;
            draggableElem.css({
                top: y + 'px',
                left: x + 'px'
            });
        }
    }
};

// ReSharper restore InconsistentNaming