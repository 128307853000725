(function (window, angular, undefined) {
    'use strict';

    /**
     * @ngdoc overview
     * @name angulartics.google.analytics
     * Enables analytics support for Google Analytics (http://google.com/analytics)
     */
    angular.module('angulartics.google.analytics', ['angulartics'])
    .config(['$analyticsProvider', function ($analyticsProvider) {

        // GA already supports buffered invocations so we don't need
        // to wrap these inside angulartics.waitForVendorApi

        $analyticsProvider.settings.pageTracking.trackRelativePath = true;

        // Set the default settings for this module
        $analyticsProvider.settings.ga = {
            // array of additional account names (only works for analyticsjs)
            additionalAccountNames: undefined,
            userId: null
        };

        function setDimensionsAndMetrics(properties) {
            if (window.ga) {
                // add custom dimensions and metrics
                for (var idx = 1; idx <= 200; idx++) {
                    if (properties['dimension' + idx.toString()]) {
                        ga('set', 'dimension' + idx.toString(), properties['dimension' + idx.toString()]);
                    }
                    if (properties['metric' + idx.toString()]) {
                        ga('set', 'metric' + idx.toString(), properties['metric' + idx.toString()]);
                    }
                }
            }
        }

        $analyticsProvider.registerPageTrack(function (path) {
            if (location.hostname.toLowerCase() === "cloud.firebrandtech.com" || location.hostname.toLowerCase() === "hup.harvard.edu" || location.hostname.toLowerCase() === "www.hup.harvard.edu") {
                if (window._gaq) {
                    _gaq.push(['_trackPageview', path]);
                    angular.forEach($analyticsProvider.settings.ga.additionalAccountNames, function (accountName) {
                        _gaq.push([accountName + '._trackPageview', path]);
                    });
                }
                if (window.ga) {
                    if ($analyticsProvider.settings.ga.userId) {
                        ga('set', '&uid', $analyticsProvider.settings.ga.userId);
                    }
                    ga('send', 'pageview', path);
                    angular.forEach($analyticsProvider.settings.ga.additionalAccountNames, function (accountName) {
                        ga(accountName + '.send', 'pageview', path);
                    });
                }
            }
        });

        /**
         * Track Event in GA
         * @name eventTrack
         *
         * @param {string} action Required 'action' (string) associated with the event
         * @param {object} properties Comprised of the mandatory field 'category' (string) and optional  fields 'label' (string), 'value' (integer) and 'noninteraction' (boolean)
         *
         * @link https://developers.google.com/analytics/devguides/collection/gajs/eventTrackerGuide#SettingUpEventTracking
         *
         * @link https://developers.google.com/analytics/devguides/collection/analyticsjs/events
         */
        $analyticsProvider.registerEventTrack(function (action, properties) {

            // Google Analytics requires an Event Category
            if (!properties || !properties.category) {
                properties = properties || {};
                properties.category = 'Event';
            }
            // GA requires that eventValue be an integer, see:
            // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#eventValue
            // https://github.com/luisfarzati/angulartics/issues/81
            if (properties.value) {
                var parsed = parseInt(properties.value, 10);
                properties.value = isNaN(parsed) ? 0 : parsed;
            }

            if (window.ga) {

                var eventOptions = {
                    eventCategory: properties.category,
                    eventAction: action,
                    eventLabel: properties.label,
                    eventValue: properties.value,
                    nonInteraction: properties.noninteraction,
                    page: properties.page || window.location.hash.substring(1) || window.location.pathname,
                    userId: $analyticsProvider.settings.ga.userId
                };

                // add custom dimensions and metrics
                setDimensionsAndMetrics(properties);

                if ($analyticsProvider.settings.ga.transport) {
                    ga('send', 'event', eventOptions, { transport: $analyticsProvider.settings.ga.transport });
                }
                else {

                }

                angular.forEach($analyticsProvider.settings.ga.additionalAccountNames, function (accountName) {
                    ga(accountName + '.send', 'event', eventOptions);
                });
            }

            else if (window._gaq) {
                _gaq.push(['_trackEvent', properties.category, action, properties.label, properties.value, properties.noninteraction]);
            }

        });

        /**
        * Track Ecommerce in GA
        * @name ecommerceTrack
        *
        * @param {string} action Required 'action' (string) associated with the event
        * @param {object} Typically an item ( product ) and a location ( url )
        *
        * @link https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce
        *
        * https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce
        */
        $analyticsProvider.registerEcommerceTrack(function (action, properties) {
            var products;
            var promoCode = "";

            if (properties.cart != null) {
                promoCode = properties.cart.promotionalCode.toString().toUpperCase();
            }

            // Google Analytics requires an Event Category
            if (!properties || !properties.category) {
                properties = properties || {};
                properties.category = 'Ecommerce';
            }
            // GA requires that eventValue be an integer, see:
            // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#eventValue
            // https://github.com/luisfarzati/angulartics/issues/81
            if (properties.value) {
                var parsed = parseInt(properties.value, 10);
                properties.value = isNaN(parsed) ? 0 : parsed;
            }

            if (window.ga) {

                var eventOptions = {
                    eventCategory: properties.category,
                    eventAction: action,
                    eventLabel: properties.label,
                    eventValue: properties.value,
                    nonInteraction: properties.noninteraction,
                    page: properties.page || window.location.hash.substring(1) || window.location.pathname,
                    userId: $analyticsProvider.settings.ga.userId
                };

                // add custom dimensions and metrics
                setDimensionsAndMetrics(properties);

                if ($analyticsProvider.settings.ga.transport) {

                }
                else {

                    // Occurs when the AddToCart button is clicked and also when the cart is updated at the item level.
                    if (action == 'AddToCart_Click') {
                        ga('ec:addProduct', {
                            'id': properties.item.ean.toString(),
                            'name': properties.item.title.toString(),
                            'variant': properties.item.format.toString(),
                            'price': properties.item.price.toString(),
                            'quantity': properties.item.quantity.toString()
                        });

                        ga('ec:setAction', 'add');
                        ga("send", "event", "Product_Detail", "click", "Add_CartItem");
                    }

                    if (action == 'ProductDetail_Impression') {
                        ga('ec:addProduct', {
                            'id': properties.item.id,
                            'name': properties.item.title.toString(),
                            'price': properties.item.price.toString(),
                            'variant': properties.item.mediaFormat.toString()
                        });

                        ga('ec:setAction', 'detail');
                    }

                    // Occurs when the checkout button is clicked, entire cart is passed along.
                    if (action == 'Checkout_Click') {
                        for (var i = 0; i <= properties.cart.items.length - 1; i++) {
                            products = properties.cart.items[i];
                            ga('ec:addProduct', {
                                'id': products.ean.toString(),
                                'name': products.title.toString(),
                                'variant': products.format.toString(),
                                'price': products.price.toString(),
                                'quantity': products.quantity.toString()
                            });
                        }

                        // Perhaps address Guest, registered, new user here...
                        ga('ec:setAction', 'checkout', {
                            'step': 1
                        });
                        ga("send", "event", "Cart_LandingPage", "click", "Checkout");
                    }

                    // Occurs when the item remove button is clicked.
                    if (action == 'Item_Remove') {
                        ga('ec:addProduct', {
                            'id': properties.item.ean.toString(),
                            'name': properties.item.title.toString(),
                            'variant': properties.item.format.toString(),
                            'price': properties.item.price.toString(),
                            'quantity': properties.item.quantity.toString()
                        });

                        ga('ec:setAction', 'remove');
                        ga("send", "event", "Cart_LandingPage", "click", "Remove_CartItem");
                    }

                    // Occurs when the item update is clicked in the primary cart page
                    if (action == 'Item_Update') {
                        ga('ec:addProduct', {
                            'id': properties.item.ean.toString(),
                            'name': properties.item.title.toString(),
                            'variant': properties.item.format.toString(),
                            'price': properties.item.price.toString(),
                            'quantity': properties.item.quantity.toString()
                        });

                        ga('ec:setAction', 'add');
                        ga("send", "event", "Cart_LandingPage", "click", "Update_CartItem");
                    }

                    // 
                    if (action == 'Save_ShippingAddress') {
                        for (var s = 0; s <= properties.cart.items.length - 1; s++) {
                            products = properties.cart.items[s];
                            ga('ec:addProduct', {
                                'id': products.ean.toString(),
                                'name': products.title.toString(),
                                'variant': products.format.toString(),
                                'price': products.price.toString(),
                                'quantity': products.quantity.toString()
                            });
                        }

                        ga('ec:setAction', 'checkout', {
                            'step': 2,
                            'id': properties.cart.externalProcessNumber,
                            'option': properties.cart.customerReference
                        });
                        ga("send", "event", "Cart_Wizard", "click", "Save_ShippingAddress");
                    }

                    // 
                    if (action == 'Save_BillingAddress') {
                        for (var a = 0; a <= properties.cart.items.length - 1; a++) {
                            products = properties.cart.items[a];
                            ga('ec:addProduct', {
                                'id': products.ean.toString(),
                                'name': products.title.toString(),
                                'variant': products.format.toString(),
                                'price': products.price.toString(),
                                'quantity': products.quantity.toString()
                            });
                        }

                        ga('ec:setAction', 'checkout', {
                            'step': 3,
                            'id': properties.cart.externalProcessNumber,
                            'option': properties.cart.customerReference
                        });
                        ga("send", "event", "Cart_Wizard", "click", "Save_BillingAddress");
                    }

                    // 
                    if (action == 'Confirm_Addresses') {
                        for (var b = 0; b <= properties.cart.items.length - 1; b++) {
                            products = properties.cart.items[b];
                            ga('ec:addProduct', {
                                'id': products.ean.toString(),
                                'name': products.title.toString(),
                                'variant': products.format.toString(),
                                'price': products.price.toString(),
                                'quantity': products.quantity.toString()
                            });
                        }

                        ga('ec:setAction', 'checkout', {
                            'step': 3,
                            'id': properties.cart.externalProcessNumber,
                            'option': properties.cart.customerReference
                        });
                        ga("send", "event", "Cart_Wizard", "click", "Confirm_Addresses");
                    }

                    // 
                    if (action == 'Shipping_Promo_Code') {
                        for (var c = 0; c <= properties.cart.items.length - 1; c++) {
                            products = properties.cart.items[c];
                            ga('ec:addProduct', {
                                'id': products.ean.toString(),
                                'name': products.title.toString(),
                                'variant': products.format.toString(),
                                'price': products.price.toString(),
                                'quantity': products.quantity.toString(),
                                'coupon': promoCode
                            });
                        }

                        ga('ec:setAction', 'checkout', {
                            'step': 4
                        });
                        ga("send", "event", "Cart_Wizard", "click", "Shipping_Promo_Code");
                    }

                    // 
                    if (action == 'ReviewOrderPage') {
                        for (var d = 0; d <= properties.cart.items.length - 1; d++) {
                            products = properties.cart.items[d];
                            ga('ec:addProduct', {
                                'id': products.ean.toString(),
                                'name': products.title.toString(),
                                'variant': products.format.toString(),
                                'price': products.price.toString(),
                                'quantity': products.quantity.toString(),
                                'coupon': properties.cart.promotionalCode.toString()
                            });
                        }

                        ga('ec:setAction', 'checkout', {
                            'step': 5
                        });
                        ga("send", "event", "Cart_Wizard", "click", "Review_Order");
                    }

                    // 
                    if (action == 'OrderComplete_Thanks') {
                        for (var e = 0; e <= properties.order.items.length - 1; e++) {
                            products = properties.order.items[e];
                            ga('ec:addProduct', {
                                'id': products.ean.toString(),
                                'name': products.title.toString(),
                                'price': products.price.toString(),
                                'quantity': products.quantity.toString(),
                                'coupon': properties.order.discountCode.toString(),
                            });
                        }

                        ga('ec:setAction', 'purchase', {
                            'id': properties.order.referenceId.toString(),
                            'affiliation': properties.order.source.toString(),
                            'revenue': properties.order.totalPrice.toString(),
                            'tax': properties.order.tax.toString(),
                            'coupon': properties.order.discountCode.toString(),
                            'shipping': properties.order.shipping.toString()
                        });
                        ga("send", "event", "Cart_ThankYou", "click", "Order_Completed");
                    }

                    //
                    if (action == 'Payment_Declined') {
                        for (var f = 0; f <= properties.cart.items.length - 1; f++) {
                            products = properties.cart.items[f];
                            ga('ec:addProduct', {
                                'id': products.ean.toString(),
                                'name': products.title.toString(),
                                'variant': products.format.toString(),
                                'price': products.price.toString(),
                                'quantity': products.quantity.toString()
                            });
                        }

                        ga('ec:setAction', 'checkout', {
                            'step': 6,
                            'id': properties.cart.externalProcessNumber,
                            'option': properties.cart.customerReference
                        });
                        ga("send", "event", "Cart_PaymentDeclined", "click", "Payment_Declined");
                    }

                    //
                    if (action == 'Payment_Error') {
                        for (var f = 0; f <= properties.cart.items.length - 1; f++) {
                            products = properties.cart.items[f];
                            ga('ec:addProduct', {
                                'id': products.ean.toString(),
                                'name': products.title.toString(),
                                'variant': products.format.toString(),
                                'price': products.price.toString(),
                                'quantity': products.quantity.toString()
                            });
                        }

                        ga('ec:setAction', 'checkout', {
                            'step': 6,
                            'id': properties.cart.externalProcessNumber,
                            'option': properties.cart.customerReference
                        });
                        ga("send", "event", "Cart_PaymentError", "click", "Payment_Error");
                    }

                }
            }

            else if (window._gaq) {
                //    _gaq.push(['_trackEvent', properties.category, action, properties.label, properties.value, properties.noninteraction]);
            }

        });


        /**
         * Exception Track Event in GA
         * @name exceptionTrack
         *
         * @param {object} properties Comprised of the mandatory fields 'appId' (string), 'appName' (string) and 'appVersion' (string) and
         * optional  fields 'fatal' (boolean) and 'description' (string)
         *
         * @https://developers.google.com/analytics/devguides/collection/analyticsjs/exceptions
         *
         * @link https://developers.google.com/analytics/devguides/collection/analyticsjs/events
         */
        $analyticsProvider.registerExceptionTrack(function (properties) {
            if (!properties || !properties.appId || !properties.appName || !properties.appVersion) {
                console.error('Must be setted appId, appName and appVersion.');
                return;
            }

            if (properties.fatal === undefined) {
                console.log('No "fatal" provided, sending with fatal=true');
                properties.exFatal = true;
            }

            properties.exDescription = properties.description;

            ga('send', 'exception', properties);
        });

        $analyticsProvider.registerSetUsername(function (userId) {
            $analyticsProvider.settings.ga.userId = userId;
        });

        $analyticsProvider.registerSetUserProperties(function (properties) {
            // add custom dimensions and metrics
            setDimensionsAndMetrics(properties);
        });

        /**
         * User Timings Event in GA
         * @name userTimings
         *
         * @param {object} properties Comprised of the mandatory fields:
         *     'timingCategory' (string),
         *     'timingVar' (string),
         *     'timingValue' (number)
         * Properties can also have the optional fields:
         *     'timingLabel' (string)
         *
         * @link https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings
         */
        $analyticsProvider.registerUserTimings(function (properties) {
            if (!properties || !properties.timingCategory || !properties.timingVar || !properties.timingValue) {
                console.error('Properties timingCategory, timingVar, and timingValue are required to be set.');
                return;
            }

            if (window.ga) {
                ga('send', 'timing', properties);
            }
        });

    }]);
})(window, window.angular);
