'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function ($http, $window, fcUrls, fcConfig) {
    return {
        restrict: 'EA',
        scope: {},
        templateUrl: 'tmpl/widgets/content/form/hupmaillist.html',
        link: HupMailListLink
    };

    function HupMailListLink(scope) {
        var vm = scope;

        vm.load = load;
        vm.send = send;
        vm.clickAllInterests = clickAllInterests;
        vm.clearAllInterests = clearAllInterests;

        load();

        function load() {
            vm.submitClicked = false;
            vm.formData = {
                SIGNUPSRC: 'HUP-website',
                MAILOPTNS: 'I would like to receive emails',
                EMLFORMAT: 'HTML'
            };
        };

        function send(data) {
            if (!vm.submitClicked) {
                vm.submitClicked = true;
                vm.successMessage = null;
                vm.errorMessage = null;
                var url = fcConfig.apiUrl + '/mail/lists/form/register';
                var parms = vm.formData;

                var apiParms = [];
                //if (vm.lapp) parms.linkedApp = vm.lapp;
                var groups = '';
                for (var p in parms) {
                    if (!parms.hasOwnProperty(p)) continue;
                    if (!parms[p]) continue;
                    if (p == 'group') {
                        var parms2 = parms[p];
                        for (var p2 in parms2) {
                            if (!parms2.hasOwnProperty(p2)) continue;
                            if (!parms2[p2]) continue;

                            if (groups.length > 0) {
                                groups += '=';
                            }
                            groups += p2;

                            var parms3 = parms2[p2];
                            for (var p3 in parms3) {
                                if (!parms3.hasOwnProperty(p3)) continue;
                                if (!parms3[p3]) continue;

                                groups += '|' + p3;
                            }
                        }
                    }
                    else {
                        apiParms.push(p + '=' + parms[p]);
                    }
                }
                if (groups && groups.length > 0) {
                    apiParms.push('groups=' + encodeURIComponent(groups));
                }

                url += (apiParms.length > 0 ? '?' + apiParms.join('&') : '');

                $http.post(url)
                    .success(function(data) {
                        vm.successMessage = 'Almost finished... We need to confirm your email address. To complete the subscription process, please click the link in the email we just sent you.';
                    })
                    .error(function (data) {
                        vm.submitClicked = false;
                        if (data.responseStatus && data.responseStatus.message) {
                            vm.errorMessage = data.responseStatus.message;
                        }
                    });
            }
        };

        function clickAllInterests() {
            if (!vm.formData.group) {
                vm.formData.group = {};
            }
            if (!vm.formData.group['Areas of interest']) {
                vm.formData.group['Areas of interest'] = {};
            }
            
            var interestGroup = vm.formData.group['Areas of interest'];
            interestGroup['Anthropology & Cultural Studies'] = true;
            interestGroup['Art & Architecture'] = true;
            interestGroup['Asian Studies'] = true;
            interestGroup['Biography'] = true;
            interestGroup['Classical Studies'] = true;
            interestGroup['Economics & Business'] = true;
            interestGroup['Education'] = true;
            interestGroup['History: American'] = true;
            interestGroup['History: European'] = true;
            interestGroup['History: World'] = true;
            interestGroup['Law'] = true;
            interestGroup['Literature'] = true;
            interestGroup['Mathematics'] = true;
            interestGroup['Medicine & Health'] = true;
            interestGroup['Medieval & Renaissance Studies'] = true;
            interestGroup['Middle Eastern Studies'] = true;
            interestGroup['Music'] = true;
            interestGroup['Philosophy'] = true;
            interestGroup['Politics'] = true;
            interestGroup['Psychology'] = true;
            interestGroup['Religion'] = true;
            interestGroup['Science'] = true;
            interestGroup['Sociology'] = true;
        };

        function clearAllInterests() {
            if (vm.formData.group) {
                vm.formData.group['Areas of interest'] = null;
            }
        };
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
