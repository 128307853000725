"use strict";

// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        restrict: 'EA',
        require: '^fcGrid',
        replace: true,
        scope: {
            name: '@',
            sortBy: '@'
        },
        template: '<th><span ng-class="getContClass()" ng-click="sort()">{{name}}<span class="sort-head-space">&nbsp;</span><i ng-class="getClass()"></i></span></th>',
        link: SortHeadLink
    };

    function SortHeadLink(scope, elems, attrs, gridCtrl) {
        var vm = scope;
        vm.sort = function() { gridCtrl.sort(vm.sortBy); };
        vm.getClass = function() { return gridCtrl.sortClass(vm.sortBy); };
        vm.getContClass = gridCtrl.sortContainerClass;
    }
};
// ReSharper restore InconsistentNaming