"use strict";

// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        restrict: 'EA',
        require: '^fcGrid',
        transclude: true,
        scope: {
            to: '@',
            index: '@'
        },
        template: '<div ng-transclude ng-click="down()"></div>',
        link: DownLink
    };

    function DownLink(scope, elems, attrs, gridCtrl) {
        var vm = scope;
        vm.down= function() {
            gridCtrl.down(vm.to, vm.index);
        }
    }
};
// ReSharper restore InconsistentNaming