'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

//require('bootstrap');

module.exports = function ($http, $sce, fcUrls, fcConfig, fcHtmlUtils, $analytics) {
    return {
        restrict: 'EA',
        scope: {
            id: '@',
            sharethis: '@',
            titleformat: '@',
            solocollection: '@'
        },
        templateUrl: 'tmpl/widgets/catalog/catalogproduct.html',
        link: CatalogProductLink
    };

    function CatalogProductLink(scope) {
        var vm = scope,
            url = fcConfig.apiUrl + '/catalogs/products/' + vm.id;

        vm.showBookstore = false;
        vm.showCover = false;
        vm.showShareThis = vm.sharethis == 'true' ? true : false;

        vm.aboutClick = aboutClick;
        vm.toggleBookstore = toggleBookstore;
        vm.coverClick = coverClick;
        vm.coverClose = coverClose;
        vm.formatClick = formatClick;
        vm.gotoCollection = gotoCollection;
        vm.hasFullCover = hasFullCover;
        vm.hoverExam = hoverExam;
        vm.openNewPage = openNewPage;

        function load() {
            vm.loaded = false;

            if (hasQueryString('exam')) {
                url += '?examOnly=true';
            }

            $http.get(url)
                .success(function (data) {
                    if (data == "") {
                        // When a user has an existing URL to a product, the service sends back a success response but the data is empty and the spinner just keeps spinning.
                        //  Ultimately a redirect or 404 would occur, but for now, just stop the spinner to help the user acknowledge the page is finished and there's no product for said URL.
                        vm.loaded = true;
                        return;
                    }
                    vm.product = data;
                    appendMeta(vm.product.metaTags);
                    vm.product.titleHtml = $sce.trustAsHtml(vm.product.title);
                    if (vm.product.subTitle) vm.product.subTitleHtml = $sce.trustAsHtml(vm.product.subTitle);
                    if (vm.product.workSeries) vm.product.workSeries = $sce.trustAsHtml(vm.product.workSeries);
                    if (vm.product.workSet) vm.product.workSet = $sce.trustAsHtml(vm.product.workSet);

                    for (var i = 0; i < vm.product.authors.length; i++) {
                        var author = vm.product.authors[i];
                        author.displayNameHtml = $sce.trustAsHtml(author.displayName);
                    }
                    if (vm.product.collections && vm.product.collections.length > 0) {
                        vm.product.primaryCollection = vm.product.collections[0];
                    }
                    if (vm.product.relatedSubjects) {
                        for (var i = 0; i < vm.product.relatedSubjects.length; i++) {
                            var relatedSubject = vm.product.relatedSubjects[i];
                            relatedSubject.encodedDisplayName = fcHtmlUtils.sanitizeForUrl(relatedSubject.displayName);
                        }
                    }
                    loadHtmlComments();
                    fcHtmlUtils.formatHtmlTitle(vm.titleformat, data);
                    
                    vm.searchInsideEAN = null;
                    if (vm.product.formats) {
                        for (var i = 0; i < vm.product.formats.length; i++) {
                            if (i == 0) {
                                if (vm.product.formats[i].mediaTag != 'EP') {
                                    vm.activeEan = vm.product.formats[i].ean;
                                }
                                else {
                                    vm.activeEan = vm.id;
                                }
                            }
                            if (!vm.searchInsideEAN && vm.product.formats[i].mediaTag == 'B') {
                                vm.searchInsideEAN = vm.product.formats[i].ean;
                            }
                            vm.product.formats[i].active = vm.product.formats[i].ean === vm.product.id;
                        }
                    }
                    vm.loaded = true;

                    $analytics.ecommerceTrack('ProductDetail_Impression', {
                        category: 'ProductDetail',
                        item: vm.product,
                        location: window.location
                    });

            });
        };

        function loadHtmlComments() {
            vm.descriptionHtml = $sce.trustAsHtml(vm.product.description);
            vm.tocHtml = $sce.trustAsHtml(vm.product.tableOfContents);
            vm.authorInfoHtml = $sce.trustAsHtml(vm.product.authorInfo);
            vm.reviewsHtml = $sce.trustAsHtml(vm.product.reviews);
            vm.awardsHtml = $sce.trustAsHtml(vm.product.awards);
            vm.rightsDescHtml = $sce.trustAsHtml(vm.product.rightsDesc);
            vm.relatedLinksHtml = $sce.trustAsHtml(vm.product.relatedLinks);
            vm.bookMessageHtml = $sce.trustAsHtml(vm.product.bookMessage);
        };

        function appendMeta(myTags) {
            if (myTags) {
                var existTags = document.getElementsByTagName('meta');
                if (existTags && existTags.length > 0) {
                    for (var i = 0; i < myTags.length; i++) {
                        var myName = myTags[i].name;
                        var myContent = myTags[i].content;
                        var myProperty = myTags[i].property;
                        if (myName && myName.length > 0 && myContent && myContent.length > 0) {
                            var foundTag = false;
                            var metaTag = null;
                            for (var j = 0; j < existTags.length && !foundTag; j++) {
                                if (myName == existTags[j].name) {
                                    metaTag = existTags[j];
                                    foundTag = true;
                                }
                            }
                            if (!metaTag) {
                                metaTag = document.createElement('meta');
                                metaTag.name = myName;
                            }
                            metaTag.content = myContent;
                            if (myProperty && myProperty.length > 0) {
                                metaTag.property = myProperty;
                            }
                            if (!foundTag) {
                                document.getElementsByTagName('head')[0].appendChild(metaTag);
                            }
                        }
                    }
                }
            }
        };

        function aboutClick(about) {
            vm.aboutSection = about;
        };

        function toggleBookstore() {
            vm.showBookstore = !vm.showBookstore;
        };

        function coverClick(product) {
            if (vm.clickableCover) {
                var ean = product.hasOwnProperty('id') ? product.id.toString() : '';
                $analytics.eventTrack('CoverArt_Click', { category: 'Product', label: 'ean: ' + ean });
                vm.showCover = true;
            }
        };

        function coverClose() {
            vm.showCover = false;
        };

        function formatClick(product) {
            $analytics.eventTrack('Format_Click', { category: 'Product', label: 'ean: ' + product.ean.toString()  });

            if (product && product.mediaTag != 'EP') {
                vm.activeEan = product.ean;
            }
            else {
                vm.activeEan = vm.id;
            }
        };

        function hoverExam(hovering) {
            vm.examHover = hovering;
        };

        function gotoCollection(href) {
            window.location = href;
        };

        function openNewPage(href) {
            window.open(href, 'title', 'height=165,width=1140,scrollbars=1,menubar=1,status=1,titlebar=1');
            return false;
        }

        function hasQueryString(name) {
            return (window.location.href.indexOf('?' + name + '=true') >= 0) ||
            (window.location.href.indexOf('&' + name + '=true') >= 0);
        };

        function hasFullCover() {
            if (!vm.clickableCover) {
                //TODO: need better way to get reference to images
                var smallImg = document.getElementById('smallThumbImage');
                var fullImg = document.getElementById('fullCoverImage');
                if (smallImg && fullImg) {
                    if (vm.product && smallImg.src == vm.product.defaultCoverUrl) return;
                    if (smallImg.naturalWidth == 0 || smallImg.naturalHeight == 0 ||
                        fullImg.naturalWidth == 0 || fullImg.naturalHeight == 0) return;
                    if (fullImg.naturalWidth > smallImg.width || fullImg.naturalHeight > smallImg.height) {
                        vm.clickableCover = true;
                    }
                } else {
                    vm.clickableCover = true;
                }
            }
        };

        load();
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
