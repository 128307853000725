'use strict';

//require('angular');
//require('angular-animate');
//require('ng-file-upload');
//require('angular-progress-arc');

var framework = require('../framework');

module.exports = angular
    .module('widgets.upload', ['ui.bootstrap', 'ngFileUpload', 'ngAnimate', 'angular-progress-arc', framework.name])
    .directive('fcMultiUpload', require('./multiupload'))
    .directive('fcMuMetadataItem', require('./multiupload.metadataitem'));