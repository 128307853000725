'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function($http, $sce, $window, fcUrls, fcConfig, fcHtmlUtils) {
    return {
        restrict: 'EA',
        scope: {
            category: '@',
            categorypaging: '@',
            parentcategory: '@',
            type: '@',
            titleformat: '@',
            descriptiontype: '@',
            view: '@',
            pagesize: '@',
            headertext: '@',
            contributorid: '@',
            examonly: '@',
            overwritetitle: '@'
        },
        //templateUrl: 'tmpl/widgets/catalog/catalogproducts.html',
        template: '<div class="fc fc-widget fc-catalog-products"><div ng-hide="loaded" class="loading-spinner">' +
            '<img src="Content/Hup/Images/spinner.gif" /></div><div ng-include="viewurl"></div></div>',
        link: CatalogProductsLink
    };

    function CatalogProductsLink(scope) {
        var vm = scope,
            viewBase = 'tmpl/widgets/catalog/catalogproducts.',
            baseUrl = fcConfig.apiUrl + '/catalogs/works';

        vm.loaded = false;
        vm.load = load;
        vm.groupItems = groupItems;

        load();

        function load(state) {
            var params = fcUrls.location().params;
            if (state && state.page) params.page = state.page;
            if (state && state.orderBy) params.orderBy = state.orderBy;
            if (vm.pagesize) params.ps = vm.pagesize;
            if (vm.type) params.type = vm.type;

            if (typeof vm.category != 'object') {
                if (vm.category) params.categoryId = vm.category;
            }
            else if (vm.category && vm.category.categoryName) {
                params.categoryId = vm.category.categoryName;
            }

            if (vm.contributorid) params.contributorId = vm.contributorid;
            if (vm.parentcategory) params.parentcategory = vm.parentcategory;
            if (vm.descriptiontype) {
                params.descriptiontype = vm.descriptiontype;
            } else {
                params.descriptiontype = 'BD'; // default to the brief description
            }
            if (vm.examonly) params.examOnly = vm.examonly == 'true' ? true : false;
            if (vm.categorypaging) params.categoryPaging = vm.categorypaging == 'true' ? true : false;
            //if (vm.showcategoryname) params.showcategoryname = vm.showcategoryname == 'true' ? true : false;
            if (vm.overwritetitle && params.displayName) vm.displayName = params.displayName;
            if (vm.filter) params.filter = vm.filter;

            var apiParams = [];
            for (var name in params) {
                if (!params.hasOwnProperty(name)) continue;
                var value = fcHtmlUtils.sanitizeForUrl(params[name]);
                apiParams.push(name + '=' + value);
            }
            var urlParams = (apiParams.length > 0 ? '?' + apiParams.join('&') : '');
            var url = baseUrl + urlParams;

            $http.get(url)
                .success(function (data) {
                    var containers = document.getElementsByClassName('fc-catalog-products'),
                        containerTop = 0;
                    if (containers.length > 0 && typeof (containers[0].offsetTop) !== 'undefined') {
                        containerTop = containers[0].offsetTop;
                    }
                    window.scrollTo(0, containerTop);
                    if (vm.overwritetitle && (data.categoryName || vm.displayName || data.searchTerm)) {
                        if (vm.displayName) data.displayName = vm.displayName;
                        if (data.categoryName) data.displayName = data.categoryName.replace(/(?: -)?(?: primary| secondary)?(?: titles)? ?$/ig, '').trim();
                        fcHtmlUtils.formatHtmlTitle(vm.titleformat ? vm.titleformat : 'hup', data);
                    }

                    if (typeof(data.titleDisplayType) === 'string') {
                        if (data.titleDisplayType.toLowerCase() === 'standard') {
                            vm.view = 'table';
                        } else if (data.titleDisplayType.toLowerCase() === 'enhanced') {
                            vm.view = 'enhanced-list';
                        }
                    }

                    vm.category = data;
                    appendMeta(vm.category.metaTags);

                    vm.viewurl = viewBase + (vm.view ? vm.view : 'table') + '.html';
                    vm.defaultThumbUrl = data.defaultThumbUrl;
                    vm.tableUrl = data.tableUrl;
                    vm.categoryName = data.categoryName;
                    vm.items = data && data.items;
                    vm.sortable = data.sortable;
                    vm.showcategoryname = data.showCategoryName;
                    vm.searchTerm = data.searchTerm;
                    if (vm.items && vm.items.values && vm.items.values.length > 0) {
                        for (var i = 0; i < vm.items.values.length; i++) {
                            var item = vm.items.values[i];
                            item.titleHtml = $sce.trustAsHtml(item.title);
                            item.descriptionHtml = $sce.trustAsHtml(item.description);
                            for (var j = 0; j < item.authors.length; j++) {
                                var author = item.authors[j];
                                author.nameHtml = $sce.trustAsHtml(author.name);
                            }
                        }
                    }
                    vm.groupedItems = null;
                    vm.loaded = true;
                    vm.$broadcast('grid-load-finish', urlParams);
                }).error(function() {
                    vm.$broadcast('grid-load-error');
                });
        };

        function groupItems(groupSize) {
            if (!vm.groupedItems || vm.groupedItems.groupSize != groupSize) {
                vm.groupedItems = [];
                vm.groupedItems.groupSize = groupSize;
                var group = null;
                if (vm.items) {
                    for (var i = 0; i < vm.items.values.length; i++) {
                        if (i == 0 || (i % groupSize == 0)) {
                            group = [];
                            vm.groupedItems.push(group);
                        }
                        if (group) {
                            group.push(vm.items.values[i]);
                        }
                    }
                }
            }
            return vm.groupedItems;
        };

        function appendMeta(myTags) {
            if (myTags) {
                var existTags = document.getElementsByTagName('meta');
                if (existTags && existTags.length > 0) {
                    for (var i = 0; i < myTags.length; i++) {
                        var myName = myTags[i].name;
                        var myContent = myTags[i].content;
                        var myProperty = myTags[i].property;
                        if (myName && myName.length > 0 && myContent && myContent.length > 0) {
                            var foundTag = false;
                            var metaTag = null;
                            for (var j = 0; j < existTags.length && !foundTag; j++) {
                                if (myName == existTags[j].name) {
                                    metaTag = existTags[j];
                                    foundTag = true;
                                }
                            }
                            if (!metaTag) {
                                metaTag = document.createElement('meta');
                                metaTag.name = myName;
                            }
                            metaTag.content = myContent;
                            if (myProperty && myProperty.length > 0) {
                                metaTag.property = myProperty;
                            }
                            if (!foundTag) {
                                document.getElementsByTagName('head')[0].appendChild(metaTag);
                            }
                        }
                    }
                }
            }
        };

    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals