/***********************************************************************
 * Multi Upload
 * ------------
 * The file workflow is as follows:
 *     1. Files are added / dropped
 *
 *     2. Association: bulk associate call to associationUrl is made
 *        in chunks of 50.   This call sends an array of file names and
 *        returns an array of the following (with each item having the
 *        same index as the cooresponding file name):
 *        {
 *            "fileName": "somefile.ext",
 *            "isAssociated": "true or false",
 *            "associatedmetadata": {
 *                 "entity name 1": {},
 *                 "entity name 2": {}
 *            }
 *        }
 *        At the end of this step, each file is marked as unready or
 *        ready based on whether isAssociated is true or not.
 *
 *     3. Lookup: there is a typeahead for each entity that
 *        is not associated.  Clicking on an entity that is already
 *        associated switches to a typeahead as well.  The typeahead
 *        triggers a lookup call to the lookupUrl in the following form:
 *        /lookup-url?entity={entity}&lookup={lookup} (this url can be
 *        in any form, but requires a {entity} and {lookup}.  Each is
 *        replaced by their respective values).
 *        This returns a list of no more than 10 entity objects.
 *
 *     4. Upload: each file does a beforeUpload call to the beforeUploadUrl
 *        sending the associatedMetadata object.  The return call takes
 *        the form:
 *        {
 *            "metadata": {},
 *            "uploadUrl": "option upload url (overrides the directive
 *                         uploadUrl)"
 *        }
 *        After the beforeUpload call returns the file is uploaded to
 *        rtn.uploadUrl || vm.uploadUrl.  Progress is tracked and on
 *        complete, the afterUpload call is made with the same metadata
 *        object.
 *
 *     5. Completed: when all associated files are uploaded any un
 *                   associated files can be associated manually.  At
 *                   this point the upload can occur again.
 *
 * Each file is in one of the following states:
 *     - pending - this is the opening status when the file is added to
 *                 the list.
 *     - unready - the file auto association has been completed but
 *                 not all metadata has been associated.
 *     - ready - all file metadata have been successfully associated
 *               and it is ready for upload.
 *     - uploading - file is currently being uploaded.
 *     - completed - file upload has completed.
 *     - error - file encountered an error.
 *
 * The following counts are maintained:
 *     - unready
 *     - ready
 *     - uploading
 *     - completed
 *     - failed
 *
 * The uploader itself has the following states:
 *     - pending - this is the opening status when no files have been
 *                 added.
 *     - ready - this indicates that the upload has run auto
 *               association on the current file list and is ready for
 *               upload.
 *     - uploading - this indicates that files are currently being
 *                   uploading. this includes pre and post upload
 *                   activities.
 *     - completed - this indicates all associated files have been
 *                   uploaded.  if any files have been associated after
 *                   this the state changes to ready.
 **********************************************************************/
'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

// NOTE: Because this is loaded with browserify all variables are isolated into
//       a JavaScript Closure and are not global.

// Upload Statuses
var UPLOAD_PENDING = 0,
    UPLOAD_UNRESOLVED = 1,
    UPLOAD_READY = 2,
    UPLOAD_RUNNING = 3,
    UPLOAD_COMPLETED = 4;

var FILTERABLE_STATUSES = [UPLOAD_UNRESOLVED, UPLOAD_READY, UPLOAD_RUNNING, UPLOAD_COMPLETED],
    VIEWABLE_STATUSES = [UPLOAD_UNRESOLVED, UPLOAD_READY, UPLOAD_RUNNING, UPLOAD_COMPLETED],
    SELECTABLE_STATUSES = [UPLOAD_UNRESOLVED, UPLOAD_READY, UPLOAD_COMPLETED],
    UPLOADABLE_STATUSES = [UPLOAD_READY],
    CANCELABLE_STATUSES = [UPLOAD_UNRESOLVED, UPLOAD_READY, UPLOAD_RUNNING, UPLOAD_COMPLETED];

// File Statuses
var FILE_ALL = 0, // Used only for filter.
    FILE_PENDING = 1,
    FILE_UNRESOLVED = 2,
    FILE_READY = 3,
    FILE_UPLOADING = 4,
    FILE_COMPLETED = 5,
    FILE_ERROR = 6;

var FILE_TO_INDICATOR = [
    null, // FILE_ALL
    'fa fa-times text-muted', // FILE_PENDING
    'fa fa-times text-muted', // FILE_UNRESOLVED
    'fa fa-times text-muted', // FILE_READY
    null, // FILE_UPLOADING
    'fa fa-check-circle text-success', // FILE_COMPLETED
    'fa fa-exclamation-circle text-danger' // FILE_ERROR
];

var DELETE_INDICATOR = 'fa fa-times text-muted',
    SUCCESS_INDICATOR = 'fa fa-check-circle text-success',
    ERROR_INDICATOR = 'fa fa-exclamation-circle text-danger';

var EXT_TO_ICON = {
    'bmp': 'fa fa-file-image-o',
    'jpeg': 'fa fa-file-image-o',
    'jpg': 'fa fa-file-image-o',
    'png': 'fa fa-file-image-o',
    'gif': 'fa fa-file-image-o',
    'svg': 'fa fa-file-image-o',
    'pdf': 'fa fa-file-pdf-o',
    'epub': 'fa fa-file-archive-o',
    'zip': 'fa fa-file-archive-o',
    'docx': 'fa fa-file-word-o',
    'doc': 'fa fa-file-word-o',
    'xlsx': 'fa fa-file-excel-o',
    'xls': 'fa fa-file-excel-o',
    'ppt': 'fa fa-file-powerpoint-o',
    'pptx': 'fa fa-file-powerpoint-o'
};

var DEFAULT_ICON = 'fa fa-file-o';
var IMAGE_ICON = 'fa fa-file-image-o';

// Export the Directive function
module.exports = function(Upload, $http, $log, _) {

    return {
        restrict: 'E',
        transclude: true,
        scope: {
            title: '@',
            associationUrl: '@',
            lookupUrl: '@',
            selectedUrl: '@',
            beforeUploadUrl: '@',
            resumeUrl: '@',
            uploadUrl: '@',
            afterUploadUrl: '@',
            fileNameTemplateUrl: '@',
            refreshWindow: '&'
        },
        templateUrl: 'tmpl/widgets/upload/multiupload.html',
        controller: MultiUploadCtrl
    };

    function MultiUploadCtrl($scope) {
        var vm = $scope;

        vm.FILE_ALL = FILE_ALL;
        vm.FILE_PENDING = FILE_PENDING;
        vm.FILE_UNRESOLVED = FILE_UNRESOLVED;
        vm.FILE_READY = FILE_READY;
        vm.FILE_UPLOADING = FILE_UPLOADING;
        vm.FILE_COMPLETED = FILE_COMPLETED;
        vm.FILE_ERROR = FILE_ERROR;

        vm.UPLOAD_PENDING = UPLOAD_PENDING;
        vm.UPLOAD_UNRESOLVED = UPLOAD_UNRESOLVED;
        vm.UPLOAD_READY = UPLOAD_READY;
        vm.UPLOAD_RUNNING = UPLOAD_RUNNING;
        vm.UPLOAD_COMPLETED = UPLOAD_COMPLETED;

        vm.DEFAULT_ICON = DEFAULT_ICON;
        vm.IMAGE_ICON = IMAGE_ICON;

        vm.metadata = [];
        vm.fileNameTemplateUrl = vm.fileNameTemplateUrl || 'MU_DEFAULT_FILE_NAME_TEMPLATE';

        this.addMetadataItem = addMetadataItem;
        vm.add = add;
        vm.confirmUpload = confirmUpload;
        vm.cancelConfirmUpload = cancelConfirmUpload;
        vm.upload = upload;
        vm.cancel = cancel;
        vm.reset = reset;
        vm.edit = edit;
        vm.lookup = lookup;
        vm.select = select;
        vm.setFilter = setFilter;
        vm.actOnIndicator = actOnIndicator;
        vm.closeUploadComplete = closeUploadComplete;
        vm.closeMsg = closeMsg;

        _init();
        _reset();

        function _init() {
            getAssetTypes();
        }

        function _reset() {
            vm.status = UPLOAD_PENDING;
            vm.filter = FILE_ALL;
            vm.filterable = false;
            vm.viewable = false;
            vm.droppable = true;
            vm.selectable = true;
            vm.cancelable = false;
            vm.resetable = false;
            vm.uploadable = false;
            vm.counts = [0, 0, 0, 0, 0, 0, 0];
            vm.allFiles = [];
            vm.files = null;
            vm.canceling = false;
            if (vm.refreshWindow) vm.refreshWindow();
        }

        function addMetadataItem(item) {
            vm.metadata.push(item);
        }

        function add(files) {
            if (!vm.associationUrl) {
                throw 'Missing selection url';
            }

            if (!files) return;

            //vm.files = vm.allFiles = files;
            vm._filesToAssociate = files;
            var fileNames = [];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                file.icon = _getFileIcon(file.name);
                file.friendlySize = file.size.toFriendlySize(2);
                file.progress = 0;
                _setStatus(file, FILE_PENDING);
                fileNames.push(file.name);
            }
            vm.fileNames = fileNames;
            $http.post(vm.associationUrl, { fileNames: fileNames })
                .then(_addSuccess, _addError);

            function _getFileIcon(fileName) {
                var dot = fileName.lastIndexOf('.');
                if (dot <= 0) return DEFAULT_ICON; // No extension or dot prefixed file.
                var ext = fileName.substr(dot + 1);
                var icon = EXT_TO_ICON[ext];
                if (icon) return icon;
                return DEFAULT_ICON;
            }

            function _addSuccess(response) {
                var associations = response.data;
                if (!Array.isArray(associations)) {
                    throw 'Selection Data is invalid';
                }
                for (var j = 0; j < associations.length; j++) {
                    file = vm._filesToAssociate[j];
                    var association = associations[j];
                    if (file.name != association.fileName) {
                        $log.error('Invalid File Spec: ' + association.fileName + ' does not match ' + file.name);
                        continue;
                    }
                    _setFileMetadata(file, association.metadata);
                    file.progress = 0;
                    vm.allFiles.push(file);
                }

                vm._filesToAssociate = null;
                vm.allFiles.sort(function(a, b) {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
                vm.setFilter();
                if (vm.refreshWindow) vm.refreshWindow();
            }

            function _addError(response) {
                vm.errorMsg = 'There was an error selecting files.';
                $log.error(response.data);
            }
        }

        function confirmUpload(e) {
            _sanitizeEvent(e);
            vm.duplicate = _checkDuplicates();
            vm.uploadConfirmable = true;
        }

        function cancelConfirmUpload(e) {
            _sanitizeEvent(e);
            vm.uploadConfirmable = false;
        }

        function upload(e) {
            _sanitizeEvent(e);
            vm.uploadConfirmable = false;

            for (var i = 0; i < vm.files.length; i++) {
                _upload(vm.files[i]);
            }

            function _upload(file) {
                if (file.status !== FILE_READY) return;
                var uploadData = {
                    fileName: file.name,
                    //contentType: file.type,
                    size: file.size,
                    metadata: _getFileMetadata(file)
                };

                if (!vm.canceling) {
                    _setStatus(file, FILE_UPLOADING);
                    $http.post(vm.beforeUploadUrl, uploadData) //Creates the Asset, etc.
                        .then(_uploadFile, _uploadFileError);
                }
                else {
                    _setAborted(file);
                }

                function _uploadFile(beforeUploadResponse) {
                    var data = beforeUploadResponse.data,
                        resumeUrl = data.resumeUrl || vm.resumeUrl,
                        uploadUrl = data.uploadUrl || vm.uploadUrl,
                        afterUploadUrl = data.afterUploadUrl || vm.afterUploadUrl;

                    uploadData.metadata = data.metadata;
                    if (!vm.canceling) {
                        vm._uploader = Upload.upload({
                            url: uploadUrl,
                            method: 'POST',
                            data: {
                                fileName: file.name,
                                file: file
                            },
                            //headers : {
                            //    'Content-Type': file.type
                            //},
                            //data: file,
                            resumeChunkSize: '1MB',
                            resumeSizeUrl: resumeUrl
                        });

                        vm._uploader.then(
                            _uploadFileSuccess,
                            _uploadFileError,
                            _uploadFileProgress
                        );
                    }
                    else {
                        _setAborted(file);
                    }

                    function _uploadFileSuccess(uploadResponse) {
                        $log.info(uploadResponse.config.data.file.name + ' Upload Completed');

                        if (!vm.canceling) {
                            $http.post(afterUploadUrl, uploadData)
                                .then(_afterUploadFileSuccess, _uploadFileError);
                        }
                        else {
                            _setAborted(file);
                        }

                        function _afterUploadFileSuccess() {
                            $log.info(file.fileName + ' Upload Completed');
                            file.msg = {
                                type: 'success',
                                title: 'Upload Succeeded',
                                info: file.name + ' has uploaded successfully!'
                            };
                            _setStatus(file, FILE_COMPLETED);
                        }
                    }

                    function _uploadFileProgress(event) {
                        if (vm.canceling && vm._uploader) {
                            vm._uploader.abort();
                        }
                        file.progress = parseInt(100.0 * event.loaded / file.size);
                        $log.info(event.config.data.file.name + ' Uploaded ' + parseInt(100.0 * event.loaded / file.size) + '%');
                    }
                }

                function _uploadFileError(response) {
                    //$log.error(response.config.data.file.name + ' Upload Failed');
                    $log.error(response);
                    if (vm.canceling) {
                        _setAborted(file);
                    }
                    else {
                        var err = _getErrorMsg(response.data);
                        file.msg = {
                            title: 'Upload Failed',
                            type: 'danger',
                            startInfo: file.name,
                            info: 'failed to upload with the following error:',
                            details: err.message
                        };
                        file.progress = 100;
                        _setStatus(file, FILE_ERROR);
                    }
                }
            }
        }

        function cancel(e) {
            _sanitizeEvent(e);
            if (vm._uploader) {
                $log.debug('Aborting Upload...');
                vm.canceling = true;
                vm._uploader.abort();
            }
            //_(vm.allFiles).forEach(function (file) {
            //    if (file.status === FILE_UPLOADING) {
            //        $log.debug('Resetting ' + file.name);
            //        _setStatus(file, FILE_READY);
            //        file.progress = 0;
            //    }
            //});

            //vm.setFilter();
        }

        function reset(e) {
            _sanitizeEvent(e);
            _reset();
        }

        function closeUploadComplete(e) {
            _sanitizeEvent(e);
            vm.uploadComplete = false;
        }

        function closeMsg(e) {
            _sanitizeEvent(e);
            vm.msg = null;
        }

        function edit(file, item, e) {
            _sanitizeEvent(e);
            //if (file.status !== FILE_READY) return;
            item.value = null;
        }

        function lookup(item, value, e) {
            //_sanitizeEvent(e);
            return $http.get(vm.lookupUrl, {
                params: {
                    name: item.name,
                    search: value
                }
            }).then(function(response) {
                return response.data;
            });
        }

        function getAssetTypes() {
            return $http.get(vm.lookupUrl, {
                params: {
                    name: 'allassettype'
                }
            }).then(function (response) {
                vm.assetTypes = response.data;
            });
        }

        function select(file, e) {
            //_sanitizeEvent(e);
            $http.post(vm.selectedUrl, { metadata: _getFileMetadata(file) })
                .then(_selectedSuccess, _selectedError);

            function _selectedSuccess(response) {
                _setFileMetadata(file, response.data.metadata);
            }

            function _selectedError(response) {
                $log.error(response);
            }
        }

        function setFilter(filter, e) {
            _sanitizeEvent(e);

            if (filter === undefined) filter = vm.filter;
            vm.filter = filter;
            if (filter === FILE_ALL) {
                vm.files = vm.allFiles;
                return;
            }
            var files = [];
            for (var i = 0; i < vm.allFiles.length; i++) {
                var file = vm.allFiles[i];
                if (file.status === filter) {
                    files.push(file);
                }
            }
            vm.files = files;
        }

        function actOnIndicator(index, e) {
            _sanitizeEvent(e);

            if (index < 0) return;
            var file = vm.allFiles[index];
            if (!file) return;
            if (file.indicator === DELETE_INDICATOR) {
                vm.allFiles.splice(index, 1);
                _setStatus(file);
                vm.setFilter();
            }
            else if (file.indicator === SUCCESS_INDICATOR ||
                     file.indicator === ERROR_INDICATOR) {
                vm.msg = file.msg;
            }
        }

        function _getFileMetadata(file) {
            var metadata = {};
            for (var j = 0; j < file.metadata.length; j++) {
                metadata[file.metadata[j].name] = file.metadata[j].value;
            }
            return metadata;
        }

        function _setFileMetadata(file, metadata) {
            var fileMetadata = [];
            var status = FILE_READY;
            for (var k = 0; k < vm.metadata.length; k++) {
                var item = vm.metadata[k];
                var metaItem = metadata ? metadata[item.name] : undefined;
                fileMetadata.push({
                    name: item.name,
                    label: item.label,
                    value: metaItem,
                    viewTemplateUrl: item.viewTemplateUrl,
                    selectTemplateUrl: item.selectTemplateUrl
                });
                if (metaItem === undefined ||
                    metaItem === null) {
                    status = FILE_UNRESOLVED;
                }
            }
            file.metadata = fileMetadata;
            _setStatus(file, status);
        }

        function _setStatus(file, status) {
            $log.info('STATUS: ' + file.name + ' - ' + file.status); // Decrement Prior Status
            if (vm.counts[file.status] > 0) vm.counts[file.status]--;
            else vm.counts[file.status] = 0;

            file.status = status;
            file.indicator = FILE_TO_INDICATOR[status];
            file.loaded = status !== FILE_PENDING;

            // Increment New Status
            if (vm.counts[status] > 0) vm.counts[status]++;
            else vm.counts[status] = 1;

            if (status === FILE_COMPLETED &&
                vm.counts[FILE_UPLOADING] <= 0 &&
                vm.counts[FILE_READY] <= 0) {
                vm.status = UPLOAD_COMPLETED;
                vm.uploadComplete = true;
            } else if (
                status === FILE_UNRESOLVED &&
                    vm.counts[FILE_READY] <= 0) {
                vm.status = UPLOAD_UNRESOLVED;
            } else if (status === FILE_READY) {
                vm.status = UPLOAD_READY;
            } else if (status === FILE_UPLOADING) {
                vm.status = UPLOAD_RUNNING;
            }

            vm.counts[FILE_ALL] =
                vm.counts[FILE_PENDING] +
                vm.counts[FILE_UNRESOLVED] +
                vm.counts[FILE_READY] +
                vm.counts[FILE_UPLOADING] +
                vm.counts[FILE_COMPLETED] +
                vm.counts[FILE_ERROR];

            // ReSharper disable UseOfImplicitGlobalInFunctionScope
            vm.filterable = vm.counts[FILE_ALL] > 0;
            vm.viewable = vm.counts[FILE_ALL] > 0;
            vm.droppable = vm.counts[FILE_ALL] <= 0;
            vm.selectable = vm.counts[FILE_UPLOADING] <= 0;
            vm.uploadable = vm.counts[FILE_READY] > 0;
            vm.cancelable = vm.counts[FILE_UPLOADING] > 0;
            vm.resetable = vm.counts[FILE_ALL] > 0 && vm.counts[FILE_UPLOADING] <= 0;
            if (vm.counts[FILE_UPLOADING] <= 0) {
                vm.canceling = false;
            }
            // ReSharper restore UseOfImplicitGlobalInFunctionScope

        }

        function _setAborted(file) {
            _setStatus(file, FILE_ERROR);
            file.msg = {
                title: 'Upload Aborted',
                type: 'danger',
                startInfo: file.name,
                info: 'failed to upload with the following error:',
                details: 'Upload has been aborted.'
            };
            file.progress = 100;
        }

        function _getErrorMsg(data) {
            var err = data && data.responseStatus;
            if (err) {
                var msg = err.message.replace(/^.+\: /g, '');
                return {
                    message: msg,
                    details: err.stackTrace
                }
            } else {
                return {
                    message: 'Server Error',
                    details: data
                }
            }
        }

        function _checkDuplicates() {
            for (var i = 0; i < vm.files.length; i++) {
                var file = vm.files[i];
                if (file) {
                    var meta = _getDuplicateMetadata(file);
                    if (meta) {
                        for (var i2 = 0; i2 < vm.files.length; i2++) {
                            if (i2 != i) {
                                var file2 = vm.files[i2];
                                if (file2) {
                                    var meta2 = _getDuplicateMetadata(file2);
                                    if (meta2) {
                                        if (meta.product == meta2.product && meta.asset == meta2.asset) {
                                            return file;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return null;
        }

        function _getDuplicateMetadata(file) {
            var meta = {};
            if (file) {
                var prod = null;
                var asset = null;
                for (var i = 0; i < file.metadata.length && (prod == null || asset == null); i++) {
                    var metadata = file.metadata[i];
                    if (metadata.name == 'product') {
                        prod = metadata.value.bookKey;
                    } else if (metadata.name == 'assetType') {
                        asset = metadata.value.name;
                    }
                }
                if (prod != null && asset != null) {
                    meta.product = prod;
                    meta.asset = asset;
                    return meta;
                }
            }
            return meta;
        }

        function _sanitizeEvent(e) {
            if (!e ||
                !e.stopPropagation ||
                !e.preventDefault) return;
            e.stopPropagation();
            e.preventDefault();
        }
    }
}
