'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function($http, $window, fcConfig) {
    return {
        scope: {
            options: '@fcCartbadge'
        },
        templateUrl: 'tmpl/widgets/cart/cartbadge.html',
        controller: CartBadgeCtrl,
        link: CartBadgeLink
    };

    function CartBadgeCtrl($scope, $controller) {
        $controller('fcCtrl', { $scope: $scope });
        $scope.loadCart = function() {
            $scope.cartItems = [];
            $scope.cartCount = 0;
            $scope.cartEmpty = true;
            $scope.showItemCount = true;
            $http.get(fcConfig.apiUrl + '/cart')
                .success(function(data) {
                    $scope.core.cartItemCount = data ? data.itemCount : 0;
                    if (!($scope.core.cartItemCount >= 0)) $scope.cartItemCount = 0;
                    $scope.showItemCount = !data.isExternal;
                });
        };


        $scope.viewCart = function() {
            $http.get(fcConfig.apiUrl + '/uri/cart')
                .success(function(data) {
                    $window.location = data.uri;
                });
        };
    }

    function CartBadgeLink(scope) {
        scope.loadCart();
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals