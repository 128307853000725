"use strict";

// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        restrict: 'EA',
        require: '^fcGrid',
        replace: true,
        scope: {
            name: '@',
            title: '@',
            resource: '&'
        },
        template: '<button class="btn btn-primary" ng-show="anySelected()" ng-click="update()"  title="{{title}}">{{name}}&nbsp;({{selectedCount}})</button>',
        link: MultiUpdateLink
    };

    function MultiUpdateLink(scope, elems, attrs, gridCtrl) {
        var vm = scope;
        vm.update = function() {
            var items = gridCtrl.calculateSelectedItems();
            vm.resource({ items: items });
        };
        vm.anySelected = function() {
            vm.selectedCount = gridCtrl.selectedCount();
            return gridCtrl.anySelected();
        };
    }
};
// ReSharper restore InconsistentNaming