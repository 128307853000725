'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        scope: {
            options: '@fcAddtocart'
        },
        templateUrl: 'tmpl/widgets/cart/addtocart.html',
        controller: AddToCartCtrl,
        link: AddToCartLink
    };

    function AddToCartCtrl($scope, $http, $controller, $log, $analytics, $window, fcConfig, fcHtmlUtils) {
        $controller('fcCtrl', { $scope: $scope });
        $scope.promptRegion = false;
        $scope.footprintBooksCountries = ['AU', 'NZ', 'PG', 'FJ'];
        $scope.application = $scope.$root.appId;

        $scope.addCartItem = function () {
            if ($scope.redirectUrl) {
                window.location = decodeURIComponent($scope.redirectUrl);
            }
            else {
                // Changed under PT story #110820080 - if ($scope.regionPrompt) { - This is how we used to have it, we're hardcoding true now since all current D2R carts are Bookmaster and this Widget option is required.
                // This alleviates the need for presses to add this widget option value to existing sites
                if (true) {
                    $scope.postRegionCartItem();
                }
                else {
                    $scope.postCartItem();
                }
            }
        };

        $scope.login = function () {
            $window.location.href = $scope.loginUrl;
        };

        $scope.closeRegion = function() {
            if ($scope.country && $scope.country.length > 0) {
                $scope.promptRegion = false;
                $http.post(fcConfig.apiUrl + '/cart/regions/' + $scope.country)
                    .success(function (data) {
                        $analytics.eventTrack('AddToCart_CloseRegion', { category: 'Cart', label: 'item: ' + $scope.ean.toString() });
                        $scope.postCartItem();
                    })
                    .error(function(data) {
                        if (!data) return;
                        $log.error(data);
                        var status = data.responseStatus || data;
                        var msg = status.message || status;
                        $scope.errorMsg = msg;
                    });
            }
        };

        $scope.closeWebSubWarn = function() {
            $scope.showWebSubWarn = false;
        }

        $scope.disableForWebSub = function () {
            // False for all but HUP
            if ($scope.application !== 'hup') {
                return false;
            }

            if ($scope.webSub === undefined && $scope.cartCountry === undefined) return false;
            if (!$scope.webSub && fcHtmlUtils.arrayContains($scope.footprintBooksCountries, $scope.cartCountry)) {
                return true;
            }
            return false;
        }

        $scope.postRegionCartItem = function() {
            $http.get(fcConfig.apiUrl + '/cart/regions')
                .success(function (data) {
                    if (data) {
                        $scope.regionalCart = data.regionalCart;
                        $scope.loginUrl = data.loginUrl;
                        $scope.cartCountry = data.cartCountry;
                        
                        // HUP Specific Complications...
                        if ($scope.application == 'hup') {
                            var countries = data.countries;

                            if (countries) {
                                // Remove footprintBooksCountries from the countries list. 
                                for (var i = countries.length - 1; i >= 0; i--) {
                                    for (var j = 0; j < $scope.footprintBooksCountries.length; j++) {
                                        if (countries[i] && (countries[i].key === $scope.footprintBooksCountries[j])) {
                                            countries.splice(i, 1);
                                        }
                                    }
                                }
                                $scope.countries = countries;
                            }
                        }
                        // This is for all apps that are NOT Harvard D2R. Regular country list.
                        else {
                            $scope.countries = data.countries;
                        }

                        if ( $scope.countries && ($scope.regionalCart === undefined || $scope.regionalCart === null)) {
                            $analytics.eventTrack('AddToCart_PromptRegion', { category: 'Cart', label: 'item: ' + $scope.ean.toString() });
                            $scope.promptRegion = true;
                        }
                        else {
                            if ($scope.disableForWebSub()) {
                                $scope.showWebSubWarn = true;
                            } else {
                                $scope.postCartItem();
                            }
                        }
                    }
                })
                .error(function (data) {
                    if (!data) return;
                    $log.error(data);
                    var status = data.responseStatus || data;
                    var msg = status.message || status;
                    $scope.errorMsg = msg;
                });
        };

        $scope.postCartItem = function () {
            if ($scope.webSub && !$scope.webSubAccepted && $scope.application === 'hup' ) {
                $scope.showWebSubPrompt();
            }
            else {
                $http.post(fcConfig.apiUrl + '/cart/' + $scope.ean)
                    .success(function (data) {
                        $analytics.eventTrack('AddToCart_Click', { category: 'Cart', label: 'item: ' + $scope.ean.toString() });
                        if (data.continue) {
                            window.location = data.continue;
                        } else {
                            window.location.reload();
                        }
                    })
                    .error(function(data) {
                        if (!data) return;
                        $log.error(data);
                        var status = data.responseStatus || data;
                        var msg = status.message || status;
                        $scope.errorMsg = msg;
                    });
            }
        };

        $scope.showWebSubPrompt = function() {
            $scope.promptWebSub = true;
        };

        $scope.acceptWebSub = function () {
            $scope.promptWebSub = false;
            $scope.webSubAccepted = true;
            $scope.postCartItem();
        };

        $scope.closeWebSub = function() {
            $scope.promptWebSub = false;
        };

        //$scope.load = function () {
        //    $http.get(fcConfig.apiUrl + '/cart/regions')
        //        .success(function (data) {
        //            if (data) {
        //                $scope.countries = data.countries;
        //                $scope.regionalCart = data.regionalCart;
        //                $scope.loginUrl = data.loginUrl;
        //                $scope.cartCountry = data.cartCountry;
        //            }
        //        });
        //};
        //$scope.load();
    }

    function AddToCartLink(scope) {
        scope.$watch('options', function(newValue) {
            if (newValue) {
                var opts = newValue.toOptions({});
                scope.type = opts.type;
                scope.buttonText = opts.buttonText || 'Add To Cart';
                scope.loadingText = opts.loadingText || 'Adding To Cart...';
                scope.ean = opts.ean;
                scope.disabled = opts.disabled;
                scope.regionPrompt = (opts.regionPrompt && opts.regionPrompt == 'true') ? true : false;
                scope.redirectUrl = opts.redirectUrl;
                scope.webSub = (opts.webSub && opts.webSub == 'true') ? true : false;
            }
        });
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
