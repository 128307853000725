'use strict';

module.exports = function() {
    return function(text, length, pre) {
        if (text === undefined || text === null) return '';
        if (isNaN(length))
            length = 15;

        var elipses = "...";

        if (text.length <= length || text.length - elipses.length <= length) {
            return text;
        }
        if (pre) {
            return elipses + String(text).substring(text.length - length - elipses.length);
        }

        return String(text).substring(0, length - elipses.length) + elipses;
    };
};
