'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function ($http, $sce, fcUrls, fcConfig) {
    return {
        restrict: 'EA',
        scope: {
            searchUrl: '@',
            titleUrl: '@'
        },
        templateUrl: 'tmpl/widgets/tmm/titlesearch.html',
        link: TitleSearchLink
    };

    function TitleSearchLink(scope) {
        var vm = scope;

        vm.search = search;
        vm.select = select;

        load();

        function load() {
            
        };

        function search(e) {
            //_sanitizeEvent(e);
            return $http.get(vm.searchUrl, {
                params: {
                    search: e
                }
            }).then(function (response) {
                var data = response.data;
                if (!data) return null;
                if (data.length == 0) {
                    data.push({displayName: 'No matches for your search.'});
                }
                return response.data;
            });
        }

        function select(item, e) {
            vm.title = null;
            if (e && e.bookKey) {
                var url = vm.titleUrl;
                url = url.replace('{bookkey}', e.bookKey);
                url = url.replace('{printingkey}', e.printingKey ? e.printingKey : '1');
                window.location = url;
            }
        }
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals