'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function($http, fcConfig) {
    return {
        restrict: 'EA',
        scope: {
            category: '@',
            type: '@'
        },
        templateUrl: 'tmpl/widgets/catalog/categoryparent.html',
        link: CatalogLink
    };

    function CatalogLink(scope) {
        var vm = scope;

        function load() {
            var url = fcConfig.apiUrl + '/catalogs/categories/' + vm.category + '/parent';
            $http.get(url)
                .success(function(data) {
                    vm.parent = data;
                });
        };

        load();
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals