"use strict";

// ReSharper disable InconsistentNaming

module.exports = function ($window, fcUrls) {
    return {
        restrict: 'EA',
        require: '^fcGrid',
        replace: true,
        scope: {
            tooltip: '@',
            glyph: '@'
        },
        template: '\
            <button class="btn btn-link btn-sm" style="padding-bottom: 1px" ng-show="anyDistribuitionFilters()" ng-click="goToDistributions()" uib-tooltip="{{tooltip}}" tooltip-placement="bottom" type="button">\
                <span class="glyphicon glyphicon-{{glyph}}"></span>\
            </button>',
        link: GoToDistributionsLink
    };

    function GoToDistributionsLink(scope, elems, attrs, gridCtrl) {
        if (!scope.tooltip) scope.tooltip = 'Go to filtered Distributions';
        if (!scope.glyph) scope.glyph = 'share';
        var vm = scope;

        vm.anyDistribuitionFilters = function() {
            var state = gridCtrl.state();
            var isDistributionFiltered = state && (state.domain || state.partner || state.service);
            return isDistributionFiltered;
        };

        vm.goToDistributions = function () {
            var state = gridCtrl.state();
            var params = {};
            if (state && state.domain) params.dm = state.domain;
            if (state && state.partner) params.pt = state.partner;
            if (state && state.service) params.sv = state.service;

            $window.top.location = '#!/dists/packages' + fcUrls.urlify(params);
        };
    };
};
// ReSharper restore InconsistentNaming