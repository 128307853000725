'use strict';

//require('angular');
require('./extensions');

module.exports = angular
    .module('widgets.framework', ['angulartics', 'angulartics.google.analytics'])
    .service('GridStates', function () { return {}; })
    .provider('fcConfig', require('./config'))
    .factory('fcUrls', require('./urls'))
    .factory('fcHtmlUtils', require('./htmlUtils'))
    .factory('fcForms', require('./forms'))
    .factory('fcCore', require('./core'))
    .factory('fcAuth', require('./auth'))
    .filter('to_trusted', require('./totrusted'))
    .filter('trunc', require('./trunc'))
    .filter('trim', require('./trim'))
    .directive('fcMsgbox', require('./msgbox'))
    .directive('fcFallbackSrc', require('./fallbackSrc'))
    .directive('fcGrid', require('./grid'))
    .directive('fcMultiSelect', require('./gridMultiSelect'))
    .directive('fcMultiUpdate', require('./gridMultiUpdate'))
    .directive('fcSearch', require('./gridSearch'))
    .directive('fcSelect', require('./gridSelect'))
    .directive('fcClearFilter', require('./gridClearFilter'))
    .directive('fcEditFromFilter', require('./gridEditFromFilter'))
    .directive('fcPaging', require('./gridPaging'))
    .directive('fcSortHead', require('./gridSorting'))
    .directive('fcDown', require('./gridDown'))
    .directive('fcGoToDistributions', require('./gridGoToDistributions'))
    .directive('pwCheck', require('./pwcheck'))
    .directive('email', require('./nospam'))
    .directive('fcBtn', require('./btn'))
    .directive('onlyNum', require('./onlynum'))
    .directive('fcDraggable', require('./draggable'))
    .directive('fcFld', require('./fld'))
    .directive('fcBool', require('./bool'))
    .directive('fcInput', require('./input'))
    .directive('fcModalform', require('./modalform'))
    .directive('imageOnLoad', require('./imageonload'))
    .directive('replaceDiacritics', require('./replaceDiacritics'))
    .controller('fcCtrl', require('./ctrl'));
