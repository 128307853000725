"use strict";

// ReSharper disable InconsistentNaming
// ReSharper disable UseOfImplicitGlobalInFunctionScope

module.exports = function () {
    return {
        restrict: 'A',
        scope: {
            src: '=fcFallbackSrc'  
        },
        link: FallbackSrcLink
    };

    function FallbackSrcLink(scope, elem, attrs) {
        if (!attrs.ngSrc) {
            elem.attr('src', scope.src);
        }
        elem.on('error', function() {
            angular.element(this).attr("src", scope.src);
        });
    }
};

// ReSharper restore UseOfImplicitGlobalInFunctionScope
// ReSharper restore InconsistentNaming
