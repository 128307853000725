'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        scope: {
            options: '@fcOrders'
        },
        template: '<div class="fc fc-widget fc-cart"><div data-ng-include="view"></div></div>',
        controller: OrdersCtrl,
        link: OrdersLink
    };

    function OrdersCtrl($scope, $http, $location, fcConfig) {
        $scope.orders = {};

        $scope.views = {
            a: 'tmpl/widgets/cart/orders.html',
            b: 'tmpl/widgets/cart/order.html'
        };

        $scope.view = $scope.views.a;
        $scope.cart = {};

        var v = $location.search()['v'];
        if (v) {
            $scope.view = $scope.views[v];
        }

        $scope.loadOrders = function(data) {
            $scope.orders = data;
        };

        $scope.loadOrder = function(data) {
            $scope.order = data;
            $scope.view = $scope.views.b;
        };

        $scope.getOrders = function() {
            $http.get(fcConfig.apiUrl + '/orders').success($scope.loadOrders);
        };

        $scope.viewOrder = function(id) {
            $scope.getOrder(id);
        };

        $scope.getOrder = function(id) {
            $http.get(fcConfig.apiUrl + '/orders/' + id).success($scope.loadOrder);
        };

        $scope.getData = function() {
            $scope.getOrders();
        }

        $scope.back = function() {
            $scope.view = $scope.views.a;
        };

    }

    function OrdersLink(scope, element, attrs, ctrl) {
        scope.getData();
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
