'use strict';

module.exports = function($scope, $location, $http, fcCore, fcAuth) {
    $scope.core = fcCore;
    $scope.auth = fcAuth;
    $scope.saveText = 'Save';
    $scope.saving = false;
    $scope.msg = null;
    $scope.msgType = null;
    $scope.msgDetails = null;

    // Original US-CA zip pattern
    ///^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *\d[A-Z]\d)$/;

    $scope.usZipPattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    $scope.caZipPattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    $scope.worldZipPattern = /^.+$/;
    $scope.onlyNumbers = /^[0-9]+$/;
    $scope.emailPattern = /\S+@\S+\.\S+/;
    $scope.strongPasswordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/; // At least 8 characters. 1 upper and 1 lower required, symbols and spaces are allowed. 

    $scope.valState = function(fld) {
        if (fld && fld.$invalid && fld.$dirty)
            return 'has-error';
        if (fld && fld.$valid && fld.$dirty)
            return 'has-success';
        return '';
    };

    $scope.valIcon = function(fld) {
        if (fld && fld.$invalid && fld.$dirty)
            return 'glyphicon-remove';
        if (fld && fld.$valid && fld.$dirty)
            return 'glyphicon-ok';
        return '';
    };

    $scope.saveDisabled = function(fm) {
        if (fm.$invalid || fm.$pristine) return true;
        var invalid = false;
        angular.forEach(fm, function(fld, key) {
            if (key[0] == '$') return;
            if (fld.$invalid) invalid = true;
        });

        return invalid;
    };

    $scope.errorReset = function(data) {
        $scope.msg = null;
        $scope.msgType = null;
        $scope.msgDetails = null;
    };

    $scope.error = function(data) {
        var err = data.responseStatus;
        if (err) {
            var msg = err.message.replace(/^.+\: /g, '');
            $scope.msg = msg;
            $scope.msgType = 'danger';
            $scope.msgDetails = err.stackTrace;
            //alert(msg);
        } else {
            $scope.msg = 'Server Error';
            $scope.msgType = 'danger';
            $scope.msgDetails = data;
            //alert('error');
        }
    };

    $scope.$on('onErrorReset', function(e, data) {
        $scope.errorReset(data);
    });

    $scope.$on('onError', function(e, data) {
        $scope.error(data);
    });

    $scope.startSaving = function() {
        $scope.saving = true;
        $scope.saveText = 'Saving...';
    };

    $scope.stopSaving = function() {
        $scope.saving = false;
        $scope.saveText = 'Save';
    };

    $scope.get = function(path, filters) {
        var first = true;
        var url = path;

        angular.forEach(filters, function(value, key) {
            if (!value) return;
            $location.search(key, value);
            if (first) {
                url += '?';
                first = false;
            } else {
                url += '&';
            }
            url += key + '=' + escape(value);
        });

        return $http.get(url, {
                headers: {
                    "X-Fcs-ApiKey": $scope.apiKey
                }
            })
            .error(function(data) {
                $scope.errorMessage = data.message;
                $scope.errorStackTracke = data.stackTrace;
            });
    };

    $scope.openUserModal = function() {
        $scope.core.showUserModal = true;
    };

    $scope.closeUserModal = function() {
        $scope.core.showUserModal = false;
    };

    $scope.showUserModal = function() {
        return $scope.core.showUserModal;
    };

};
