'use strict';

module.exports = function($window, $http, fcConfig) {
    return {
        parse: parse,
        urlify: urlify,
        location: location,
        go: go
    };

    function parse(url) {
        var a = document.createElement('a');
        a.href = url;
        var query = "";
        if (a.href.includes("?")) {
            let arr = a.href.split('?')
            query = "?" + arr[arr.length - 1];
        }

        return {
            source: url,
            title: document.title,
            protocol: a.protocol.replace(':', ''),
            host: a.hostname,
            port: a.port,
            query: query,
            params: (function() {
                var ret = {},
                    seg = query.replace(/^\?/, '').split('&'),
                    len = seg.length,
                    i = 0,
                    s;
                for (; i < len; i++) {
                    if (!seg[i]) {
                        continue;
                    }
                    s = seg[i].split('=');
                    ret[s[0]] = decodeURIComponent(s[1].replace(/\+/g, ' '));
                }
                return ret;
            })(),
            file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
            hash: a.hash.replace('#', ''),
            path: a.pathname.replace(/^([^\/])/, '/$1'),
            relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
            segments: a.pathname.replace(/^\//, '').split('/')
        };
    }

    function location() {
        return parse($window.location);
    }

    function go(uri, absolute) {
        var fullUri = absolute ? uri : fcConfig.apiUrl + uri;
        $http.get(fullUri)
            .success(function(data) {
                $window.top.location = data.uri;
            });
    }

    function urlify(object) {
        if (object == null) return '';
        var urlParams = [];
        for (var p in object) {
            if (!object.hasOwnProperty(p)) continue;
            if (!object[p]) continue;
            urlParams.push(p + '=' + object[p]); // todo: implement EncodeUri()
        }
        return (urlParams.length > 0 ? '?' + urlParams.join('&') : '');
    }
};