"use strict";

// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        restrict: 'EA',
        require: '^fcGrid',
        replace: true,
        scope: {
            name: '@',
            options: '=',
            selector: '@?',
            default: '@?',
            preLoad: '@?',
            removeEmpty: '@?'
        },
        template: '\
            <select class="form-control input-sm filter" ng-change="changeFilter()" ng-model="model" ng-options="{{selector}}">\
                <option value="" ng-if="!removeEmpty">-{{name}}-</option>\
            </select>',
        compile: function() {
            return {
                pre: SelectPre,
                post: SelectLink
            };
        }
    };

    function SelectPre(scope) {
        scope.selector = scope.selector || 'x.key as x.value for x in options';
        scope.isDefaulted = typeof scope.default !== 'undefined' && scope.default !== null;
        scope.preLoad = scope.isDefaulted || (typeof scope.preLoad === 'string' && scope.preLoad.toLowerCase() === 'true');
        scope.removeEmpty = typeof scope.removeEmpty === 'string' && scope.removeEmpty.toLowerCase() === 'true';
    }

    function SelectLink(scope, elems, attrs, gridCtrl) {
        var vm = scope;
        var initModel = vm.isDefaulted ? vm.default : '';
        vm.model = initModel;
        if (gridCtrl.stateValue(vm.name)) vm.model = gridCtrl.stateValue(vm.name);

        vm.$on('grid-clear-filters', function() { vm.model = initModel; });
        vm.$on('grid-change-filter', function(broadcast, name, value) {
            if (vm.name == name) {
                vm.model = value;
                vm.changeFilter();
            }
        });
        vm.changeFilter = function() { gridCtrl.changeFilter(vm.name, vm.model); };
        if (vm.preLoad) vm.changeFilter();
    }
};
// ReSharper restore InconsistentNaming