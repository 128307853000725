'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function ($http, $window, fcConfig, fcHtmlUtils) {
    return {
        restrict: 'EA',
        scope: { defaultOrderBy: '@' },
        templateUrl: 'tmpl/widgets/catalog/catalogsearch.html',
        link: CatalogSearchLink
    };

    function CatalogSearchLink(scope) {
        var vm = scope;
        init();
        loadSearchUri();

        vm.search = function () {
            var parms = [];
            for (var name in vm.filters) {
                if (!vm.filters.hasOwnProperty(name)) continue;
                var value = fcHtmlUtils.sanitizeForUrl(vm.filters[name]);
                parms.push(name + '=' + value);
            }
            var location = vm.searchUri;
            location += location.indexOf('?') < 0
                            ? (parms.length > 0 ? '?' + parms.join('&') : '')
                            : (parms.length > 0 ? '&' + parms.join('&') : '');
            $window.top.location = location;
        };

        function init() {
            vm.filters = {};
            vm.filters.search = '';
            vm.filters.page = 1;
            if (vm.defaultOrderBy) vm.filters.orderBy = vm.defaultOrderBy;
        };

        function loadSearchUri() {
            $http.get(fcConfig.apiUrl + '/uri/products').success(function (data) {
                vm.searchUri = data.uri;
            });
        };
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals