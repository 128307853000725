'use strict';

module.exports = function() {
    return {
        formatHtmlTitle: formatHtmlTitle,
        sanitizeForUrl: sanitizeForUrl,
        arrayContains: arrayContains,
        camelize: camelize,
        alertForFailure: alertForFailure
    };

    function formatHtmlTitle(fmt, data) {
        // formats the content of the <title> tag
        var htmlTitle = '';

        if (typeof (fmt) === 'undefined' || typeof (data) === 'undefined') return;
        if (typeof (data.fullTitleText) !== 'undefined') {
            htmlTitle = data.fullTitleText;
        } else if (typeof (data.displayName) !== 'undefined') {
            htmlTitle = data.displayName;
        } else if (typeof (data.searchTerm) !== 'undefined') {
            htmlTitle = "Search Results for:  " + data.searchTerm;
        } else {
            return;
        }

        if (fmt === 'hup') {
            //if (typeof (data.subTitle) !== 'undefined') htmlTitle += ': ' + data.subTitle;
            if (typeof (data.authors) !== 'undefined' && data.authors.length > 0) {
                htmlTitle += ' - ' + data.authors.map(function(a) { return a.name; }).join(', ');
            }
            htmlTitle += ' | Harvard University Press';
        }
        document.title = htmlTitle;
    }

    function sanitizeForUrl(str) {
        str += '';
        if (str) {
            str = decodeURIComponent(str.replace(/\+/g, ' '))
                .replace(/</g, '')
                .replace(/>/g, '');
        }
        return encodeURIComponent(str);
    }

    function arrayContains(a, obj) {
        for (var i = 0; i < a.length; i++) {
            if (a[i] === obj) {
                return true;
            }
        }
        return false;
    }

    function camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
            return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
        }).replace(/\s+/g, '');
    }

    function alertForFailure(response) {
        // uses the alert box to display error message from server.
        if (response.hasOwnProperty('data') && response.data.hasOwnProperty('responseStatus') && response.data.responseStatus.hasOwnProperty('message')) {
            alert('Received an Error:\n' + response.data.responseStatus.message);
        } else {
            alert('Received an Error.');
        }
    }
};