'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function($http, fcConfig) {
    return {
        scope: {
            options: '@fcUser'
        },
        templateUrl: 'tmpl/widgets/user/user.html',
        controller: UserCtrl,
        link: UserLink
    };

    function UserCtrl($scope, $location, $controller, $analytics) {
        $controller('fcCtrl', {
            $scope: $scope
        });

        $scope.zipPattern = $scope.usZipPattern;

        $scope.getUser = function() {
            $http.get($scope.userUrl)
                .success($scope.loadUser)
                .error($scope.error);
        };

        $scope.getMailListUrl = function() {
            $http.get(fcConfig.apiUrl + '/app/maillist')
                .success(function(data) {
                    if (data && data.length > 0) {
                        $scope.mailListUrl = data;
                    } else {
                        $scope.mailListUrl = null;
                    }
                    $scope.optInActive = true;
                })
                .error($scope.error);
        };

        $scope.loadUser = function(user) {
            if (user) {
                $scope.user = user;
                $scope.user.isLoaded = true;
            } else user = $scope.user;
            $scope.info = {
                name: user.name,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                role: user.role,
                optInMail: user.optInMail,
                hasAccount: user.roleCode != 5 &&
                            user.roleCode != 0 ? true : false
            };
            $scope.infoForm.$setPristine();
            $scope.pwd = null;
            $scope.pwdForm.$setPristine();
            $scope.billing = user.billingAddress;
            $scope.shipping = user.shippingAddress;
            $scope.editMode = 0;
            $scope.countries = user.countries;
            $scope.auth.user.name = user.name;
            $scope.optIn = {
                emailOptIn: user.optInMail
            };
            $scope.updateShippingCountry();
            $scope.updateBillingCountry();
            if ($scope.wizard) {
                if (!$scope.shipping || !$scope.shipping.address1) $scope.editMode = 3;
                else if (!$scope.billing) $scope.editMode = 4;
            }
            $scope.userUrl = fcConfig.apiUrl + '/users/' + user.id;
          //  $analytics.eventTrack('Load', { category: 'User', label: 'name: ' + user.name.toString() + ' email: ' + user.email.toString() });
        };

        $scope.changeSameAsShipping = function() {
            $scope.billing = {};
            if (!$scope.sameAsShipping) return;
            var b = $scope.billing,
                s = $scope.shipping;
            b.firstName = s.firstName;
            b.lastName = s.lastName;
            b.address1 = s.address1;
            b.address2 = s.address2;
            b.country = s.country;
            b.city = s.city;
            b.state = s.state;
            b.zip = s.zip;
            b.phone = s.phone;

            $scope.updateBillingCountry();
        };

        $scope.getCountry = function(address) {
            var c = address ? address.country : 'US';
            if (!$scope.countries || $scope.countries.length <= 0) return {};
            for (var i = 0; i < $scope.countries.length; i++) {
                var country = $scope.countries[i];
                if (country.key == c) {

                    if (c == 'US') {
                        $scope.zipPattern = $scope.usZipPattern;
                    } else if (c == 'CA') {
                        $scope.zipPattern = $scope.caZipPattern;
                    } else {
                        $scope.zipPattern = $scope.worldZipPattern;
                    }
                    return country;
                }
            }
            return {};
        };

        $scope.updateBillingCountry = function() {
            $scope.billingCountry = $scope.getCountry($scope.billing);
        };

        $scope.updateShippingCountry = function() {
            $scope.shippingCountry = $scope.getCountry($scope.shipping);
        };

        $scope.saveInfo = function() {
            $http.put($scope.userUrl, $scope.info)
                .success($scope.loadUser)
                .error($scope.error);
        };

        $scope.cancelEdit = function() {
            $scope.loadUser();
        };

        $scope.changePassword = function() {
            $http.post($scope.userUrl + '/password', $scope.pwd)
                .success($scope.loadUser)
                .error($scope.error);
        };

        $scope.saveBilling = function() {
            $scope.billing.zip.toUpperCase();
            $http.put($scope.userUrl + '/address/billing', $scope.billing)
                .success(function (userData) {
                    var url = fcConfig.apiUrl + '/cart';
                    $http.get(url).success(function (cartData) {
                        if (cartData) {
                            $analytics.ecommerceTrack('Save_BillingAddress', {
                                category: 'Cart',
                                cart: cartData,
                                location: window.location,
                                user: userData
                            });
                        }
                    });
                    $scope.loadUser(userData);
                })

                .error($scope.error);
        };
        $scope.saveShipping = function() {
            $scope.shipping.zip = $scope.shipping.zip.toUpperCase();
            $http.put($scope.userUrl + '/address/shipping', $scope.shipping)
                .success(function (userData) {
                    var url = fcConfig.apiUrl + '/cart';
                    $http.get(url).success(function (cartData) {
                        if (cartData) {
                            $analytics.ecommerceTrack('Save_ShippingAddress', {
                                category: 'Cart',
                                cart: cartData,
                                location: window.location,
                                user: userData
                            });
                        }
                    });
                    if (!userData.isGuestRegionsActivate) {
                        $scope.checkCartRegion();
                    }
                    $scope.loadUser(userData);
                })
                .error($scope.error);
        };

        $scope.gotoWizardContinue = function() {
            if ($scope.user) {
                var url = fcConfig.apiUrl + '/cart';
                $http.get(url).success(function (cartData) {
                    if (cartData) {
                        $analytics.ecommerceTrack('Confirm_Addresses', {
                            category: 'Cart',
                            cart: cartData,
                            location: window.location,
                            user: $scope.user
                        });
                    }
                });
            }

            $scope.$parent[$scope.wizard]();
        };

        $scope.saveEmailOpt = function() {
            if ($scope.showOptInMail && $scope.optInActive &&
                !$scope.mailListUrl && $scope.info) {
                $scope.info.optInMail = $scope.optIn.emailOptIn;
                $scope.saveInfo();
            }
        };

        $scope.checkCartRegion = function() {
            $http.get(fcConfig.apiUrl + '/cart/regions?internationalDump=true')
                .success(function(data) {
                    if (data) {
                        $scope.internationalCart = data.regionalCart;
                    }
                });
        };

        $scope.internationalClose = function() {
            $scope.internationalCart = false;
            window.location = '';
        };
    }

    function UserLink(scope) {
        scope.$watch('options', function(newValue) {
            if (newValue) {
                var opts = newValue.toOptions({
                    title: 'Account'
                });

                scope.displayShipping = opts.shipping == 'true' ? true : false;
                scope.displayBilling = opts.billing == 'true' ? true : false;
                scope.displayPassword = opts.password == 'true' ? true : false;
                scope.wizard = opts.wizard;
                scope.modal = opts.modal == 'true' ? true : false;
                scope.title = opts.title;
                scope.userId = opts.id;
                scope.userUrl = scope.userId ? fcConfig.apiUrl + '/users/' + scope.userId : fcConfig.apiUrl + '/users/me';
                scope.displayOnly = opts.displayOnly == 'true' ? true : false;
                scope.horizontal = opts.horizontal == 'true' ? true : false;
                scope.showOptInMail = opts.optInMail == 'true' ? true : false;
                if (opts.optInLabel && opts.optInLabel.length > 0) {
                    scope.optInLabel = opts.optInLabel;
                }
                scope.mailListUrl = null;
                scope.optInActive = null;

                scope.getUser();
                if (scope.showOptInMail) {
                    scope.getMailListUrl();
                }
            }
        });
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
