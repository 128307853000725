'use strict';

module.exports = function ($provide) {
    var self = this;
    var rootUrl = '<!-- substitute:rootUrl -->';
    var proto = /^http:/.test(document.location) ? 'http:' : 'https:';
    rootUrl = rootUrl.replace(/http:|https:/, proto);
    var apiUrl = rootUrl + '/api/v2';

    var apiKey = document.getElementsByTagName("body")[0].getAttribute("data-fc-api");
    if (!apiKey) apiKey = document.getElementsByTagName("body")[0].getAttribute("fc-api");

    var appId = document.getElementsByTagName("body")[0].getAttribute("data-fc-app");
    if (!appId) appId = document.getElementsByTagName("body")[0].getAttribute("fc-app");
    if (!appId) appId = "fcs";

    var direct = document.getElementsByTagName("body")[0].getAttribute("data-fc-direct");
    if (direct === undefined || direct === null) direct = document.getElementsByTagName("body")[0].getAttribute("fc-direct");
    direct = Boolean(direct);

    self.rootUrl = rootUrl;
    self.apiUrl = apiUrl;
    self.apiKey = apiKey;
    self.appId = appId;
    self.direct = direct;
    self.locationEnabled = (apiKey == 'D2GdyXXTtk6O0aKqAFzFTg' || appId == 'cloud');

    $provide.decorator('$browser', function($delegate, $log) {
        self._onUrlChange = $delegate.onUrlChange;
        self._url = $delegate.url;
        $delegate.onUrlChange = function (newUrl, newState) {
            if (!self.locationEnabled) return;
            $log.debug('onUrlChange: ' + newUrl + '['+newState+']');
            self._onUrlChange(newUrl, newState);
        };
        $delegate.url = function (url, replace, state) {
            if (!self.locationEnabled) return "";
            var rtn = self._url(url, replace, state);
            $log.debug('url(' + url + ', ' + replace + ', ' + state + '): ' + rtn);
            return rtn;
        };
        return $delegate;
    });

    var sid = '__fc_session__';

    var simg = document.getElementById(sid);

    if (!simg) {
        simg = document.createElement('img');
        simg.id = sid;
        simg.width = '0';
        simg.height = '0';
        simg.style.display = 'none';
        if (apiKey) {
            simg.src = apiUrl + '/session?api_key=' + apiKey + '&app=' + appId;
        } else {
            simg.src = apiUrl + '/session?app=' + appId;
        }

        document.body.insertBefore(simg, document.body.firstChild);
    }

    self.$get = function() {
        return self;
    };
}
