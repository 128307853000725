'use strict';

// ReSharper disable InconsistentNaming

module.exports = function () {
    return {
        restrict: 'C',
        link: NoSpamLink
    };

    function NoSpamLink(scope, elem, attrs, ctrl) {
        var vm = scope;
        if (elem && elem.length > 0) {
            var element = elem[0];
            if (element.rel) {
                deobfuscate(element);
            }
        }
    }

    function deobfuscate(ele) {
        var result = ele.rel.split('').reverse().join('').replace('//', '@').replace(/\//g, '.');
        ele.href = 'mailto:' + result;
        if (ele.className.indexOf('replaceText') >= 0) {
            ele.textContent = result;
        }
    }
};

// ReSharper restore InconsistentNaming