'use strict';

module.exports = function(fcForms) {
    return {
        restrict: 'EA',
        require: '^form',
        template: '<div class="form-group" ng-class="groupClass">' +
            '<label class="control-label" ng-class="labelCol" for="{{for}}">{{label}}</label>' +
            '<div ng-class="valueCol"><div ng-transclude></div>' +
            '<span class="glyphicon" ng-class="iconClass" ng-hide="checkbox"></span>' +
            '</div></div>',

        replace: true,
        transclude: true,

        scope: {
            label: '@'
        },

        link: function(scope, element, attrs, formCtrl) {
            var size = attrs.fcInput || attrs.size;
            if (!size || size.indexOf(',') <= 0) {
                scope.vertical = true;
            } else {
                var sizes = size.split(',');
                scope.labelCol = 'col-sm-' + sizes[0];
                scope.valueCol = 'col-sm-' + sizes[1];
            }

            var type = null;
            if (element.find('input').length > 0 &&
                element.find('input').attr('name')) {
                type = 'input';
                if (element.find('input').attr('type') == 'checkbox') {
                    scope.checkbox = true;
                }
            }
            if (!type &&
                element.find('select').length > 0 &&
                element.find('select').attr('name')) {
                type = 'select';
            }
            if (!type &&
                element.find('textarea').length > 0 &&
                element.find('textarea').attr('name')) {
                type = 'textarea';
            }

            if (!type) {
                throw 'Invalid control group.';
            }
            var input = element.find(type);
            var name = input.attr('name');
            var formName = formCtrl.$name;
            var id = [name, name].join('_');
            input.attr('id', id);
            if (!scope.checkbox) input.removeClass('form-control').addClass('form-control');

            var label = scope.label || '';
            input.attr('placeholder', label.endsWith(':') ? label.substring(0, label.length - 1) : label);

            scope.for = id;

            var invalidExp = [formName, name, '$invalid'].join('.');
            var validExp = [formName, name, '$valid'].join('.');
            var dirtyExp = [formName, name, '$dirty'].join('.');

            function updateValidation() {
                if (!name) return;
                //var modelCtrl = scope.$parent[formName][name];
                var modelCtrl = formCtrl[name];
                if (!modelCtrl) return;
                var invalid = modelCtrl.$invalid;
                var valid = modelCtrl.$valid;
                var dirty = modelCtrl.$dirty;

                var grp = scope.checkbox ? '' : 'has-feedback ';
                var icon = scope.checkbox ? '' : 'form-control-feedback ';

                if (invalid && dirty) {
                    grp += 'has-error';
                    icon += 'glyphicon-remove';
                } else if (valid && dirty) {
                    grp += 'has-success';
                    icon += 'glyphicon-ok';
                }

                scope.groupClass = grp;
                scope.iconClass = icon;
            }

            updateValidation();

            var fmScope = fcForms.findScope(scope.$parent.$parent, formCtrl);
            if (fmScope) {
                fmScope.$watch(invalidExp, updateValidation);
                fmScope.$watch(validExp, updateValidation);
                fmScope.$watch(dirtyExp, updateValidation);
            }

            /*
            scope.$on('show-form-validity', function() {
                updateValidation(true);
            });
            */

        }
    };
};
