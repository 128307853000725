'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

//require('bootstrap');

module.exports = function ($http, $sce, fcUrls, fcConfig) {
    return {
        restrict: 'EA',
        scope: {
            id: '@'
        },
        templateUrl: 'tmpl/widgets/catalog/authordetail.html',
        link: AuthorDetailLink
    };

    function AuthorDetailLink(scope) {
        var vm = scope,
            baseUrl = fcConfig.apiUrl + '/catalogs/contributors';

        //vm.aboutClick = aboutClick;

        load();

        function load() {
            if (vm.id) {
                baseUrl += '/' + vm.id;
            }
            
            var parms = fcUrls.location().params;

            var apiParms = [];
            for (var p in parms) {
                if (!parms.hasOwnProperty(p)) continue;
                if (!parms[p]) continue;
                apiParms.push(p + '=' + parms[p]);
            }
            var url = baseUrl + (apiParms.length > 0 ? '?' + apiParms.join('&') : '');

            $http.get(url)
                .success(function (data) {
                    vm.author = data;
                    vm.author.nameHtml = $sce.trustAsHtml(vm.author.name);
                    vm.author.bioHtml = $sce.trustAsHtml(vm.author.bio);
                    //vm.titleHtml = $sce.trustAsHtml(vm.product.title);
                    //loadHtmlComments();

                    //vm.searchInsideEAN = null;
                    //if (vm.product.formats) {
                    //    for (var i = 0; i < vm.product.formats.length && !vm.searchInsideEAN; i++) {
                    //        if (i == 0) {
                    //            if (vm.product.formats[i].mediaTag != 'EP') {
                    //                vm.activeEan = vm.product.formats[i].ean;
                    //            }
                    //            else {
                    //                vm.activeEan = vm.id;
                    //            }
                    //        }
                    //        if (vm.product.formats[i].mediaTag == 'B') {
                    //            vm.searchInsideEAN = vm.product.formats[i].ean;
                    //        }
                    //    }
                    //}
                });
        };
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
