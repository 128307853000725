'use strict';

require('angular');

module.exports = angular
    .module('widgets.catalog', [])
    .directive('fcCategoryList', require('./categorylist'))
    .directive('fcCategoryParent', require('./categoryparent'))
    .directive('fcCatalogProduct', require('./catalogproduct'))
    .directive('fcCatalogProducts', require('./catalogproducts'))
    .directive('fcCatalogSearch', require('./catalogsearch'))
    .directive('fcCatalogSearchAdvanced', require('./catalogsearchadvanced'))
    .directive('fcAuthorDetail', require('./authordetail'))
    .directive('fcCatalogProductCrawl', require('./catalogproductcrawl'));
