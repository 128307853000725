'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        scope: {
            options: '@fcEngine'
        },
        templateUrl: 'tmpl/widgets/engine/engine.html',
        controller: EngineCtrl,
        link: EngineLink
    };

    function EngineCtrl($scope, $http, $timeout, fcConfig) {
        $scope.editing = false;
        $scope.saving = false;
        $scope.saveText = 'Save';
        $scope.status = null;
        $scope.showDetails = false;

        $scope.loadEngine = function(data) {
            $scope.status = data.status;
            $scope.isRunning = (data.state == 'Running');
            $scope.isStopping = (data.state == 'Stopping');
            $scope.isStopped = (data.state == 'Stopped');
            $scope.friendlyScheduleType = data.friendlyScheduleType;
            $scope.scheduleType = data.scheduleType;
            $scope.processingStarted = data.processingStarted;
            $scope.lastRanAt = data.lastRanAt;
            $scope.scheduleTypes = data.scheduleTypes;
        };

        $scope.loadEngineAndClose = function(data) {
            $scope.loadEngine(data);
            $scope.closeDetails();
        };

        $scope.openDetails = function() {
            $scope.showDetails = true;
            $scope.engine = $http.get(fcConfig.apiUrl + '/engine').success($scope.loadEngine);
        };

        $scope.closeDetails = function() {
            $scope.showDetails = false;
        };

        $scope.edit = function() {
            $scope.editing = true;
        };

        $scope.save = function() {
            $scope.saving = true;
            $scope.saveText = 'Saving...';
            $http.post(fcConfig.apiUrl + '/engine/reschedule?scheduleType=' + $scope.scheduleType)
                .success(function(data) {
                    $scope.loadEngine(data);
                    $scope.editing = false;
                    $scope.saving = false;
                    $scope.saveText = 'Save';
                });
        };

        $scope.cancel = function() {
            $scope.editing = false;
        };

        $scope.stop = function() {
            if (!confirm('Are you sure you want to SHUTDOWN the Processing Engine?')) return;
            $http.post(fcConfig.apiUrl + '/engine/shutdown').success($scope.loadEngineAndClose);
        };

        $scope.start = function() {
            if (!confirm('Are you sure you want to STARTUP the Processing Engine?')) return;
            $http.post(fcConfig.apiUrl + '/engine/run').success($scope.loadEngine);
        };

        $scope.startProcessing = function() {
            if (!confirm('Are you sure you want to START PROCESSING MANUALLY?')) return;
            $http.post(fcConfig.apiUrl + '/engine/process').success($scope.loadEngineAndClose);
        };

        $scope.updateStatus = function() {
            if (window.__SUSPEND_ENGINE_STATUS__) return;
            window.__ENGINE_UPDATING__ = true;
            $http.get(fcConfig.apiUrl + '/engine/status', {
                headers: {

                    "X-NoRedirect": true
                }
            }).success(function(data) {
                window.__ENGINE_UPDATING__ = false;
                $scope.status = data.trim();
                $timeout($scope.updateStatus, 5000);
            }).error(function (data) {
                window.top.location.href = '/#!/login';
                window.top.location.reload();
            });
        };

        $scope.updateStatus();
    }

    function EngineLink(scope) {
        scope.$watch('options', function(newValue) {
            if (newValue) {
                var opts = newValue.toOptions();
                scope.layout = opts.layout;
                scope.layoutStyle = '';
                if (opts.layout == 'left')
                    scope.layoutStyle = 'nav navbar-nav';
                else if (opts.layout == 'right')
                    scope.layoutStyle = 'nav navbar-nav navbar-right';
            }
        });
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
