'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function($http, fcConfig) {
    return {
        restrict: 'EA',
        scope: {
            title: '@',
            parent: '@'
        },
        templateUrl: 'tmpl/widgets/catalog/categorylist.html',
        link: CatalogLink
    };

    function CatalogLink(scope) {
        var vm = scope;
        
        function load() {
            var url = fcConfig.apiUrl + '/catalogs/categories/'+vm.parent+'/categories';

            $http.get(url)
                .success(function(data) {
                    vm.categories = data;
                    vm.loadStatus = null;
                });
        };
        
        load();
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
