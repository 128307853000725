'use strict';

// ReSharper disable UnusedLocals
// ReSharper disable InconsistentNaming

module.exports = function ($http, fcUrls, fcConfig) {
    return {
        restrict: 'EA',
        scope: {},
        templateUrl: 'tmpl/widgets/content/resources.html',
        link: ResourcesLink
    };

    function ResourcesLink(scope) {
        var vm = scope;

        vm.uploadOptions = { target: fcConfig.apiUrl + '/content/web/resource/img' };
        vm.load = load;
        vm.create = create;
        vm.edit = edit;
        vm.cancel = cancel;
        vm.clear = clear;
        vm.upload = upload;
        vm.retry = retry;
        vm.error = error;
        vm.result = result;
        vm.save = save;
        vm.remove = remove;

        load();

        function load(state) {
            vm.page = null;
            var parms = fcUrls.location().params;

            var apiParms = [];
            if (state) {
                vm.state = state;
                if (state.page) parms.p = state.page;
                if (state.orderBy) parms.o = state.orderBy;
            }
            if (vm.category) parms.category = vm.category;
            for (var p in parms) {
                if (!parms.hasOwnProperty(p)) continue;
                if (!parms[p]) continue;
                apiParms.push(p + '=' + parms[p]);
            }
            var url = fcConfig.apiUrl + '/content/web/resources' + (apiParms.length > 0 ? '?' + apiParms.join('&') : '');

            $http.get(url)
                .success(function (data) {
                    vm.items = data;
                    vm.$broadcast('grid-load-finish');
                }).error(function () {
                    vm.$broadcast('grid-load-error');
                });
        };

        function create() {
            vm.item = { fbCompleted: false, adding: false, editing: false, error: null };
        };

        function edit(resource) {
            create();
            vm.item.editing = true;
            vm.item.id = resource.id;
            vm.item.imageUrl = resource.imageUrl;
            vm.item.type = resource.type;
            vm.item.displayName = resource.name;
            vm.item.linkUrl = resource.linkUrl;
            vm.item.linkText = resource.linkText;
        };

        function cancel(flow) {
            vm.item = null;
            flow.cancel();
        };

        function clear(flow) {
            create();
            flow.cancel();
        };

        function upload(file) {
            create();
            file.resume();
        };

        function retry(file) {
            create();
            file.retry();
        };

        function result(flow, file, message) {
            //vm.item.pendingResult = { flow: flow, file: file, message: message };
            var resultObj = JSON.parse(message);
            if (resultObj.link) {
                vm.item.imageUrl = resultObj.link;
            }
            vm.item.adding = true;
        };

        function error(flow, file, message) {
            vm.item.error = 'Failed to upload file.';
        };

        function save(flow) {
            var postUrl = fcConfig.apiUrl + '/content/web/resources';
            if (vm.item.editing) {
                postUrl += '/' + vm.item.id;
            }

            postUrl += '?imageUrl=' + encodeURIComponent(vm.item.imageUrl);
            if (vm.item.type) {
                postUrl += '&type=' + vm.item.type;
            }
            if (vm.item.displayName) {
                postUrl += '&name=' + vm.item.displayName;
            }
            if (vm.item.linkUrl) {
                postUrl += '&linkUrl=' + encodeURIComponent(vm.item.linkUrl);
            }
            if (vm.item.linkText) {
                postUrl += '&linkText=' + vm.item.linkText;
            }
            
            $http.post(postUrl).success(function (data) {
                vm.item.fbCompleted = true;
                cancel(flow);
                load(vm.state);
            });
        };

        function remove(flow) {
            if (vm.item.id && confirm('Are you certain you want to delete this?')) {
                var postUrl = fcConfig.apiUrl + '/content/web/resources/' + vm.item.id;
                $http.delete(postUrl).success(function(data) {
                    cancel(flow);
                    load(vm.state);
                });
            }
        }
    }
};

// ReSharper restore InconsistentNaming
// ReSharper restore UnusedLocals
