"use strict";

// ReSharper disable InconsistentNaming

module.exports = function() {
    return {
        restrict: 'EA',
        require: '^fcGrid',
        replace: true,
        scope: {
            tooltip: '@',
            glyph: '@',
            resource: '&'
        },
        template: '\
            <button class="btn btn-primary btn-sm" style="padding-bottom: 1px" ng-show="displayable()" ng-click="edit()" uib-tooltip="{{tooltip}}" tooltip-placement="bottom" type="button">\
                <span class="glyphicon glyphicon-{{glyph}}"></span>\
            </button>',
        link: FilterUpdate
    };

    function FilterUpdate(scope, elems, attrs, gridCtrl) {
        if (!scope.tooltip) scope.tooltip = 'Edit Using Filters';
        if (!scope.glyph) scope.glyph = 'cog';
        var vm = scope;
        vm.displayable = function () { return gridCtrl.getTotalRecords() && gridCtrl.anyFilters() && !gridCtrl.anySelected(); };
        vm.edit = function() {
            var state = gridCtrl.state();
            state.itemCount = gridCtrl.getTotalRecords();
            vm.resource({ state: state });
        };
    }
};
// ReSharper restore InconsistentNaming